/* ==========================================================================
Utilities
  > Align
========================================================================== */

.u-float-left--always {
  float: left;
}

@include bp-small-medium {

  .u-float-left--for-s-m {
    float: left;
  }

  .u-float-right--for-s-m {
    float: right;
  }
}

@include bp-medium {

  .u-float-left {
    float: left;
  }

  .u-float-right {
    float: right;
  }
}

.u-fl--l {
  float: left;
}

@media (min-width: 768px) and (max-width: 1023px) {
  .u-fl--l--m--max--l {
    float: left;
  }
}

.u-clear {
  clear: both;
}

.u-text-center {
  text-align: center;

  ul, ol {
    list-style-position: inside;
  }
}

.u-text-left {
  text-align: left;
}

.u-text-right {
  text-align: right;
}

@include bp-medium-large {
  .u-ta--r--ml {
    text-align: right;
  }
}

@include bp-large {
  .u-ta--r--l {
    text-align: right;
  }

  .u-fl--r--l {
    float: right;
    margin: 0 0 8px 16px;
  }
}

@include bp-max-large {
  .u-text-center-for-max-m {
    text-align: center;
  }
}

.u-cols--2 {
    columns: 2;
}

// Text -- centre

.u-ta--c {
  text-align: center;
}

// Text - l

.u-ta--l {
  text-align: left;
}


// Text - r

.u-ta--r {
  text-align: right;
}

// Text centre @max l

@include bp-max-large {
  .u-ta--c--max--l {
    text-align: center;
  }
}
