/* ==========================================================================
Components
  > Cards
========================================================================== */

// Vertical (Default)

.c-card {
  @include t-ui--global;
  display: flex;
  flex-direction: column;
  position: relative;
  background: var(--color--blue--light);
  padding: 32px;

  &.c-card--bg--white {
    background: #fff;
  }

  &.c-card--bg--tertiary--grey {
    background: var(--color--tertiary--grey);
  }

  // Media (If image)

  &.c-card--image {
    .c-card__media {
      @include fluid-aspect(16 9);

      @include bp-large {
        padding-bottom: 0;
      }
    }
  }

  // Media

  .c-card__media {
    margin-bottom: 32px;
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;

    &.offset {
      margin: -24px -32px 24px -32px;
      padding-bottom: 8px;
    }

    @include bp-max-large {
      &.offset--max--l {
        margin: -24px -32px 24px -32px;
        padding-bottom: 8px;
      }
    }

    .c-icon {
      color: var(--color--primary);
      font-size: 64px;
    }

    img {
      object-fit: cover;
      margin: 0;
    }
  }

  // Media - Open

  .c-media--open {
    text-align: center;

    img {
      width: auto;
    }
  }

  // Media - Book

  .c-media--book {
    img {
      max-width: 128px;
    }
  }

  // Body

  .c-card__body {

    // Label

    .c-card__label {
      display: inline-block;
      background-color: $color-secondary;
      font-size: 14px;
      font-weight: 500;
      color: #fff;
      padding: 0 8px;
      height: 24px;
      line-height: 24px;
      text-transform: uppercase;
      margin-bottom: 16px;

      .c-icon {
        display: inline-block;
        position: relative;
        top: 1px;
        margin: 0 2px 0 0;
        font-weight: 400;
      }
    }

    .h2 {
      font-size: 30px;
      line-height: 38px;
      margin-bottom: 8px;
    }

    .h4 {
      font-size: 18px;
      margin-bottom: 8px;
      color: #444;
    }

    p {
      font-size: 16px;
      margin-bottom: 24px;
    }
  }

  // Action

  .c-card__action {

    .c-btn {

      &:hover {
        color: #fff;
      }
    }
  }

  // Sub Text

  .c-card__sub-text {
    padding-top: 32px;

    p {
      margin: 0;
      border-top: 1px solid var(--color--grey--medium);
      padding-top: 24px;
      font-weight: 300;
      font-style: italic;
    }
  }

  // Image

  &.c-card--image {

    .c-card__media {
      overflow: hidden;
      margin-top: -32px;
      margin-left: -32px;
      margin-right: -32px;
      border-radius: var(--global--radius) var(--global--radius) 0 0;
    }
  }

  // No image

  &.c-card--no-image {

    .c-card__media {
      display: none;
    }
  }

  // Horizontal @m

  &.c-card--horizontal {

    @include bp-medium {

      .c-card__content {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 100%;
      }

      .c-card__body {
        flex-grow: 1;
      }
    }

    // Horizontal @l

    @include bp-large {
      @include t-card--horizontal--l;
    }
  }

  ul {
    margin-bottom: 24px;
  }

  // Border - left (default - tertiary blue))

  &.c-card--b--l {
    border-left: none;

    &:before {
      content: '';
      background: var(--color--tertiary--blue);
      border-radius: var(--global--radius) 0 0 var(--global--radius);
      width: 8px;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
    }

    // Tertiary - Green

    &.c-card--tertiary--green:before {
      background: var(--color--tertiary--green);
    }

    // Tertiary - Wine

    &.c-card--tertiary--wine:before {
      background: var(--color--tertiary--wine);
    }

    // Tertiary - Orange

    &.c-card--tertiary--orange:before {
      background: var(--color--tertiary--orange);
    }

    // Tertiary - Grey

    &.c-card--tertiary--grey:before {
      background: var(--color--tertiary--grey);
    }

  }
}

// Product

.c-card--product {
  width: 100%;
  max-width: 299px;
}

// Cards on bg img

.has--bg--img {

  .c-card {
    border: none;
  }

  .h3 {
    color: #fff;
  }
}

// Cards full width @l

@include bp-large {
  .l12 .c-card.c-card--horizontal .c-card__media {
    width: 298px;
  }
}

// Gated Content

.c-card--gated-content {
  border-bottom: 5px solid var(--color--tertiary--blue);

  ul {
    @include clearfix();
    list-style-type: none;
    margin: 16px 0 32px 0;
    padding: 0;

    li {
      padding-left: 32px;
      padding-right: 16px;
      margin-bottom: 8px;
      position: relative;

      @include bp-large {
        width: 50%;
        float: left;
      }

      .moon-check {
        color: $color-secondary;
        position: absolute;
        top: 2px;
        left: 0;
        font-size: 20px;
      }
    }
  }
}

/*

  9.19. LMS Promotional

    CTA text edge-case @l & @l offset

    Hide context >= 1024px - < 1160px

    <a href="">VIEW <span class="c-btn__text--context">COURSE</span></a>

*/

@include bp-large {

  .offset-l3,
  .o-sidebar {

    .c-btn__text--context {
      display: none;

      @media (min-width: 1160px) {
        display: inline-block;
      }
    }
  }
}