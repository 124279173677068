/* ==========================================================================
   #FLEX GRID
   ========================================================================== */

.c-flex-grid {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0% -1%;

  .c-mini-card {
    width: 100%;
    min-height: auto;
    margin: 0 1% 2rem 1%;

    @include bp-medium {
      width: 48%;
    }

    @include bp-large {
      width: 31%;
    }
  }
}

.c-flex-grid.c-flex-grid--2 {

  .c-mini-card {
    width: 100%;
    min-height: auto;
    margin: 0 1% 2rem 1%;

    @include bp-medium {
      width: 48%;
    }

    @include bp-large {
      width: 48%;
    }
  }
}
