///* ========================================================================
//   #ANIMATIONS
//   ======================================================================== */

@keyframes popup {
  0% {
    height: 0;
  }
  100% {
    height: 7px;
  }
}

@keyframes fadeup {
  0% {
    top: 55%;
    opacity: 0;
  }
  100% {
    opacity: 1;
    top: 50%;
  }
}

@keyframes fadein {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
