/* ==========================================================================
   #HEADER
   ========================================================================== */

// Skip to Content

.skip-to-content-link {
  background: #2e5797;
  height: 40px;
  left: 50%;
  top: 0px;
  padding: 8px;
  position: absolute;
  transform: translate(-50%, -100%);
  transition: transform 0.3s;
  z-index: 999;
  color: #fff;
  font-size: 16px;
}

  .skip-to-content-link:focus {
    transform: translate(-50%, 0%);
    font-weight: bold;
  }

.c-header {
  width: 100%;
  margin: 0;
  height: var(--header--height);
  border-bottom: 1px solid var(--color--grey);
  background: #fff;

  @include clearfix;

  @include bp-large {

    > .o-container {
      height: 103px;
    }
  }
}

.c-header-top {
  display: flex;
  align-items: center;
}


.c-logo {
  display: flex;
  width: 100px;
  transform: translateX(-16px);

  @include bp-medium {
    width: 144px;
    transform: translateX(0);
  }


  @include bp-large {
    width: 160px;
  }

  img {
    width: 100%;
    height: auto;
  }
}

.c-myaccount-btn {
  font-size: 22px;
  color:#222;

  @include bp-large {
    display: none;
  }

  &:hover {
    color: $color-blue;
    text-decoration: none;
  }
}

.c-menu-btn {
  text-decoration: none;
  font-size: 25px;
  text-align: center;
  display: block;
  cursor: pointer;
  color: $color-dark-grey;

  @include bp-large {
    display: none;
  }
  &.active {
    color: $color-blue;
  }

  &:hover {
    text-decoration: none;
  }
}

// Main Menu Wrapper

.c-header-menu-wrapper {
  display: flex;
  height: var(--header--height);
  line-height: var(--header--height);
  margin-left: auto;
  transform: translateX(var(--gutter ));

  @include bp-medium {
    transform: translateX( calc( var(--gutter) / 2 ));
  }


  @include bp-large {
    display: none;
  }
}

.c-btn--header,
.c-menu-btn.moon-hamburger {
  width: var(--tap-target--size);
  line-height: var(--header--height);
  text-align: center;
  text-decoration: none;
}


// Site Search

.c-site-search {
  display: none;
  background: $color-white;
  position: relative;

  button {
    font-size: 19px;
    display: block;
    width: 46px;
    height: 46px;
    line-height: 46px;
    position: absolute;
    top: 0;
    right: 0;
    color: var(--color--primary);
    padding: 0;
    margin: 0;
    cursor: pointer;
    background: none;
    border-radius: 50px;
    border: 2px dashed transparent;

    &:focus {
      color: $color-secondary;
      outline: none;
      border: 2px dashed var(--color--primary);
    }
  }

  &.c-site-search--main {
    @include bp-large {
      width: 100%;
      max-width: 428px;
      display: inline-flex;
      margin: auto;
      transform: translateX(28px); // Centres to page
      height: 46px;
    }

    input[type="text"] {
      font-size: 18px;
      font-weight: 400;
      display: inline-block;
      background: none;
      height: 46px;
      padding: 0 48px 0 24px;
      line-height: 44px;
      border: 2px solid $color-primary;
    }
  }

  &.c-search--secondary {
    display: block;
    margin: 0;
    width: 100%;
    min-width: auto;
    max-width: none;

    input[type="text"] {
      padding-right: 40px;
    }

    button {
      font-size: 16px;
      color: #444;
      height: 40px;
      line-height: 40px;
      right: 0;
    }
  }
}

// Utility Menu

.c-utility-menu-container {
  width: 100%;
  margin: 0;
  padding: 0;
  background-color: $color-dark-grey;
  height: 46px;
  line-height: 46px;
  display: none;


  @include bp-large {
    display: block;
  }

  .o-container {
    display: flex;
    align-items: center;
    width: 100%;
   }

  a {
    text-decoration: none;
  }
}
  // Sites menu in Utility Menu

.c-site-dropdowns {
}
.c-sites-dropdown {
  position: relative;
  float: left;
  transition: all 0.2s ease-in-out;
  margin: 0 32px 0 0;

  &.active {
    &:after {
      content: "";
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      height: 4px;
      background: #fff;
    }
  }

  > a {
    display: inline-block;

    &:focus {
      outline: 2px dotted #fff;
    }
  }

  a {
    color: #f5f5f5;
    font-size: 14px;
  }

  button {
    cursor: pointer;
    color: #f5f5f5;
    font-size: 16px;
    vertical-align: middle;
    background: none;
    border: 0;
    padding: 8px;
    margin: 0;

    &:focus {
      outline: 2px dotted #fff;
    }

    &:hover {
      text-decoration: none;
      color: #fff;
    }

    .moon-chevron-down {
      font-size: 0.625rem;
      font-weight: 800;
    }
  }
}

  .c-sites-dropdown-content {
    display: none;
    position: absolute;
    top: 45px;
    width: 275px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    padding: 0;
    z-index: 2;
    background-color: $color-dark-grey;
    border-radius: 0 0 var(--global--radius) var(--global--radius);

    ul {
      @include shadow(3);
      border-radius: 0 0 var(--global--radius) var(--global--radius);
      margin: 0;
      padding: 8px 0 12px 0;

      li {
        width: 100%;
        list-style-type: none;
        line-height: initial;
        margin: 0;
        position: relative;

        a {
          padding: 8px 16px;
          display: block;
          line-height: 24px;

          &:hover {
            text-decoration: underline;
            background: #222;
          }
        }
      }
    }
  }

  .c-sites-dropdown.active .c-sites-dropdown-content {
    display: block;
  }

  .c-utility-menu {
    display: none;

    .moon-facebook-square, .moon-twitter-square {
      font-size: 1.4rem;
    }

    @include bp-large {
      display: flex;
      margin: 0 0 0 auto;
    }

    li {
      list-style-type: none;
      display: inline-block;
      margin-left: 1rem;
      vertical-align: middle;
      color: #f5f5f5;

      a {
        color: #f5f5f5;
        font-size: 16px;
        vertical-align: middle;
        font-family: $font-body;

        &:hover {
          text-decoration: none !important;
          color: #fff;
        }

        &:focus {
          outline-color: #fff;
        }

        span {
          font-size: 16px;
          margin-right: 4px;
          position: relative;
          top: 1px;
        }
      }
    }
  }

  // CTA Buttons

  .c-cta-btns {

    a {
      text-transform: uppercase;
      height: 48px;
      line-height: 48px;
      border: none;
      font-weight: 500;
      font-size: 18px;

      &:hover {
        border: none;
      }

      &:last-child {
        margin-left: 15px;
      }
    }
  }

  // Search
  .c-search-btn {
    @include t-reset--btn;
    font-size: 19px;

    @include bp-large {
      display: none;
    }

    span.moon-search {
      color: #222;
      line-height: var(--header--height);
      cursor: pointer;
      display: block;
    }

    &.active {

      span.moon-search {
        color: $color-blue;
      }
    }
  }

  .c-header--items {
    display: none;

    @include bp-large {
      display: block;
      font-size: 0px; // Removes whitespace
    }
  }
