/* ==========================================================================
   #LISTS
   ========================================================================== */

// Shared

.c-list {
  @include clearfix();
  margin: 0;
  padding: 0;
  list-style: none;

  a {
    text-decoration: underline;
  }

  // No decoration

  &.c-list--no-dec {
    a {
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.communities {
    columns: 3;
    margin-top: 3rem;

      li {
        margin-bottom: 1rem;
      }
    }
}

// Browse - AZ

.c-list__browse--az {
  margin-bottom: 32px;
  li {
    float: left;
    margin: 0 4px 4px 0;
    a {
      background: $color-very-light-grey;
      width: 48px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      &:hover {
        color: #fff;
        background: $color-secondary;
      }
    }
    &.c-view-all a {
      width: 100px;
      font-size: 14px;
    }
  }
}

// Browse - Listing

.c-list__browse--listing {
  >li {
    margin-bottom: 16px;
    padding-bottom: 16px;
    border-bottom: 1px solid $color-light-grey;
    >a {
      font-weight: 600;
    }
    >ul {
      list-style: none;
      margin: 0;
      padding: 0;
    }
    &:last-child {
      border-bottom: none;
    }
  }
}

// With Icons

.c-list--has-icons {
  list-style: none;
  margin-left: 0;

  li {
    position: relative;
    margin-bottom: $gutter / 2;
    padding-left: $gutter;

    &:before {
      position: absolute;
      left: 0;
    }
  }
}

// Icons Inline

.c-list--icons {
  list-style: none;
  margin-left: 0;

  li {
    display: inline;

    &::before {
      vertical-align: middle;
    }
  }

  &.c-list--icons--24 li:before {
    font-size: 24px;
  }
}

// Checklist

.c-list--checklist {
  li {

    &:before {
      font-family: 'icomoon' !important;
      // content: unicode($moon-tick); 
      color: $color-primary;
    }

  }
}

.c-list--numberlist {
  padding: 0;
  margin: 0 0 2rem 0;
  counter-reset: counter;

  li {
    position: relative;
    
    list-style-type: none;
    margin-bottom: 1rem;
    padding-left: 45px;

    &:before {
      top: 0;
      left: 0;
      counter-increment: counter;
      content: counter(counter);
      background: $color-primary;
      color: #fff;
      height: 30px;
      width: 30px;
      display: block;
      position: absolute;
      text-align: center;
      line-height: 26px;
      font-size: 1.1rem;
      border-radius: 50px;
    }

  }
}

// Index Page

.c-list.index {
  margin-bottom: var(--gutter);

  li a {
    display: block;
    padding: 10px 10px 10px 15px;
    border: 1px solid #ddd;
    border-left: 3px solid #ddd;
    border-bottom: 0;
    color: #333;
    font-family: $font-body;
    text-decoration: none!important;

    &:hover {
      background: #f7f7f7;
      border-left: 3px solid $color-primary;
      color: #444;
    }

    span {
      display: inline-block;
      width: 40px;
      text-align: right;
      margin-right: 8px;
    }
  }
}


.c-list.index li:last-of-type a {border-bottom: 1px solid #ddd;}

// Separated

.c-list--separated {

  li:not(:last-child) {
    border-bottom: 1px solid var(--color--grey--light);
    margin-bottom: 12px;
    padding-bottom: 12px;
  }

  a {
    text-decoration: none;
    color: #444;

    &:hover {
      @include t-link--hover;
    }
  }
}

// Minor

.c-list--minor {
  font-size: 14px;
  margin-bottom: 8px;

  li {
    margin-bottom: 4px;
  }
}

// External

.c-list--external {
  font-size: 16px;

  li {
    padding-left: 38px;

    a {
      display: block;
      margin: 0 0 10px 0;
      color: $color-text;
      text-decoration: none;

      &:hover {
        color: #2e5797 !important;
      }

      span {
        font-size: 12px;
        display: inline-block;
        margin-left: 8px;
        color: #2E5797;
      }
    }
  }
}

// Inline Block

.c-list--inline-b {

  li {
    display: inline-block;
    margin-right: 16px;
    margin-bottom: 16px;
  }

  @include bp-large {

    &.u-ta--r--ml {
      li {
      margin-right: 0;
      margin-left: 24px;
      }
    }
  }
}

// Bullet Custom

.c-list--bullet-custom {
  @include t-list--bullet-custom;
}

// Icon

.c-list--icon {
  padding-left: 24px;

  li {
    position: relative;
    margin-bottom: 4px;
  }

  .c-icon {
    position: absolute;
    top: 4px;
    left: -24px;
    color: var(--color--grey--dark);
  }
}

// Margin bottom

.c-list--mb--12 {
  li {
    margin-bottom: 12px;
  }
}
