/* ==========================================================================
   Utilities
     > UI -- Global
   ========================================================================== */

/*
  Shadow & Border Radius
*/

.u-ui--global {
  @include t-ui--global;
}
