/* ==========================================================================
Components
  > Quick Links
========================================================================== */

.c-quicklinks {
  padding: var(--padding--wrapper--quick-link);

  .c-quicklink {
    display: flex;
    flex-direction: column;

    @include bp-small {
      flex-direction: row;
    }
    // Img
    img {
      flex: 112px 0 0;
      width: 100%;
      max-width: 112px;
      margin: 0 0 var(--gutter);

      @include bp-small {
        margin: 0 var(--gutter) 0 0;
        align-self: flex-start;
      }
    }
    // Detail
    .c-quicklink__detail {

      .h5 {
        margin-bottom: 0.75rem;
        font-weight: 500;
      }

      .c-btn {
        font-size: 16px;
      }
    }
    // Mini
    &.c-quicklink--mini {

      img {
        flex: 88px 0 0;
        max-width: 88px;

        @include bp-small {
          margin-right: calc(var(--gutter) / 2);
        }
      }
    }
  }
}
