/* ==========================================================================
   #Slick Carousel
   ========================================================================== */


.c-carousel {
  margin-left: calc(var(--gutter) / 2 * -1);
  margin-right: calc(var(--gutter) / 2 * -1);
  position: relative;
  opacity: 0;
  transition: opacity 0.3s ease-in-out;
  visibility: hidden;

  img {
    width: 100%;
  }

  &.slick-initialized {
    visibility: visible;
    opacity: 1;
  }

  &::before {
    content: "";
    position: absolute;
    display: block;
    width: 0.7rem;
    top: 0;
    left: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
  }

  &::after {
    content: "";
    position: absolute;
    display: block;
    width: 0.7rem;
    top: 0;
    right: 0;
    bottom: 0;
    background: #fff;
    z-index: 1;
  }

  div.slick-slide {
    position: relative;
    margin: 0;
    padding: 0 calc(var(--gutter) / 2);

    &:focus {
      outline: 0;
    }
  }

  a {
    color: #222;

    &:hover {
      color: var(--color--primary);
    }
  }
  // Slick Arrow
  .slick-arrow {
    position: absolute;
    top: 35%;
    z-index: 3;
    color: var(--color--primary);
    cursor: pointer;
    font-family: 'icomoon';
    background: rgba(234,239,246,0.66);
    font-size: 0;
    border: 0;
    width: 28px;
    height: 56px;
    text-align: center;

    &::after {
      font-size: 16px;
    }

    &.slick-prev {
      left: -11px;
      border-radius: 0 10px 10px 0;

      &::after {
        content: "\e913";
      }
    }

    &.slick-next {
      right: -11px;
      border-radius: 10px 0 0 10px;

      &::after {
        content: "\e914";
      }
    }

    &:hover {
      color: $color-secondary;
    }
  }
  // Dots
  .slick-dots {
    margin: auto;
    float: none;
    display: inline-block;
    text-align: center;
    width: 100%;
    margin-top: 0rem;

    li {
      display: inline-block;
      margin: 0 4px;


      button {
        font-size: 0;
        background: #fff;
        border: 1px solid var(--color--grey--dark);
        width: 16px;
        height: 16px;
        display: block;
        padding: 0;
        border-radius: 50%;
        cursor: pointer;

        &:hover {
          border-color: var(--color--primary);
        }

        &:focus {
          outline: 0;
        }
      }

      &.slick-active {

        button {
          background: var(--color--primary);
          border-color: var(--color--primary);
        }
      }
    }
  }
  // Featured Articles
  &.c-featured-articles {

    p.label {
      font-size: 1.2rem;
    }
  }

  h4 {
    @include t-heading--h5;
    margin-top: 28px;
    margin-bottom: 6px;
    font-weight: 500;
  }
}
