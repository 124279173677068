/* ==========================================================================
   #PAGE
   ========================================================================== */

/**
 * Simple page-level setup.
 *
 * 1. Set the default `font-size` and `line-height` for the entire project.
 * 2. Force scrollbars to always be visible to prevent awkward ‘jumps’ when
 *    navigating between pages that do/do not have enough content to produce
 *    scrollbars naturally.
 * 3. Ensure the page always fills at least the entire height of the viewport.
 */

html {
  background: #fff;
  color: $color-text;
  font-family: $font-body;
  font-weight: 400;
  font-size: 1em; /* [1] */ /* 16px */
  line-height: 1.5; /* [1] */
  min-height: 100%; /* [3] */
  min-width: 320px;
  overflow-y: scroll; /* [2] */
}

body {
  background: #fff;
}

b, strong {
  font-weight: 600;
}

body.c-narrow {
  max-width: 1600px;
  margin: auto;
  box-shadow: 0 0px 10px 0 rgba(0,0,0,0.15);
}

:focus {
  outline: 2px dotted $color-primary;
}
