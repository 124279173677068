/* ==========================================================================
Tools
  > Links
========================================================================== */

// Hover

@mixin t-link__hover {
  color: var(--color--primary);
  text-decoration: underline;
}
