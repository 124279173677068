/* ==========================================================================
   Utilities
     > Width
   ========================================================================== */

// 80

.u-w--80 {
  width: 80px;
}

// Max 160

.u-mw--160 {
  width: 100%;
  max-width: 160px;
}
