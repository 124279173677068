/* ==========================================================================
   #SLICK BANNER
   ========================================================================== */

/* Hide until loaded */

.c-slick-slider__hold {
  @include clearfix;
  background-color: $color-very-light-grey;
}

.c-slick-slider {
  @include clearfix;
  height: 400px;
  background-color: $color-very-light-grey;
  visibility:hidden;
  opacity:0;
  transition: opacity 0.66s;
  transition-delay: 0.66s;

  @include bp-small {
    height: 500px;
  }
}

/* Show when initialised */

.c-slick-slider.slick-initialized {
  opacity: 1;
  visibility: visible;
}

.c-slick-slider .slick-slide {
  width: 100%;
  height: 400px;
  position: relative;
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;

  &::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.6);
}

  @include bp-small {
    height: 500px;
  }

  .o-container {
    position: relative;
    padding: 0;
    height: 400px;

    @include bp-small {
      padding: 0 25px;
      height: 500px;
    }
  }
}

.slick-initialized {

  .c-banner-content {
    position: absolute;
    padding: 30px;
    bottom: 0;
    left: 0;
    position: relative;
    top: 50%;
    transform: translateY(-50%);
    opacity: 0;
    animation: fadeup 1s;
    animation-fill-mode:forwards;
    animation-delay: 1s;
    z-index: 5;

    @include bp-small {
      max-width: 600px;
    }

    @include bp-large {
      padding: 0px;
    }

    p.title {
      font-family: $font-headings;
      text-transform: uppercase;
      font-size: 2em;
      color: $color-white;
      font-weight: 500;
      line-height: 1.4;
      margin-bottom: 1em;
    }
  }

}
