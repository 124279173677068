/* ==========================================================================
   #CATEGORIES
   ========================================================================== */

.c-categories {
  border: 1px solid #ccc;
  padding: 1.5rem;

  ul {
    margin: 0;
    padding: 0;

    li {
      list-style-type: none;
      padding-bottom: 0.5rem;
      margin-bottom: 0.5rem;
      border-bottom: 1px solid #ddd;

      &:last-child {
        border-bottom: 0;
      }
      
      a {
        color: #666;
      }
    }
  }
}