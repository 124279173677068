/* ==========================================================================
   #TITLE POD
   ========================================================================== */

.c-title-pod {
  box-shadow: 0 10px 10px 0 rgba(0,0,0,0.13);

  h3 {
    background: #E5EFF5;
    color: #333;
    text-align: center;
    font-size: 1.5rem;
    padding: 0.5rem 0;
    margin: 0;
  }
  
  .c-title-pod--content {
    padding: 2rem;

    p:last-of-type {
      margin: 0;
    }
  }
}
