/* ==========================================================================
   #HALF CARD
   ========================================================================== */

.c-half-cards {
  width: 100%;
  border-left: 6px solid $color-primary;


  .c-half-card {
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 1px solid $color-light-grey;
    margin-left: -1px;
    margin-bottom: -1px;
    transition: all 0.5s ease;

    @include bp-medium {
      width: 50%;
    }


  .c-half-card--desc {
    width: 100%;
    padding: 2rem;

    p {
      font-size: 0.875em;
    }

    @include bp-medium {
      width: 100%;
    }
  }
}

  .c-half-card--img {
    width: 100%;
    margin-bottom: 1em;

    img {
      max-height: 40px;
    }
  }
}
