/* ==========================================================================
   #LAYOUT
   ========================================================================== */

/* Widgets */

.o-widget {
  margin-top: 1.3em;

  h2, h3 {
    margin: 0;
  }
  .o-widget-content {
    min-height: 100px;
    padding: 1em;
    background: $color-very-light-grey;
    position: relative;
    @include clearfix();
  }
}

// layouts

.o-main-content {
  margin: 30px 0;

  @include bp-medium {
    margin: 40px 0;
  }

  @include bp-large {
    margin: 60px 0;
  }

  .row {
    overflow: hidden;
  }
}

.o-narrow {
  max-width: 69rem!important;
  margin: auto;
}

.o-narrower {
  max-width: 62.5rem!important;
  margin: auto;
}

.o-narrowest {
  max-width: 43rem!important;
  margin: auto;
}

// Sidebar

.o-sidebar {

  @include bp-large {
    padding-top: 3rem;
    float: left;
    width: 20%;
  }
}

.o-rightrail {
  /*margin: 4rem auto 0 auto;*/
  max-width: 30em;

  @include bp-large {
    padding-top: 3rem;
    float: left;
    width: 20%;
  }
}

.o-has-sidebar {
  padding-top: 3rem;

  @include bp-large {
    float: left;
    width: 75%;
    margin-left: 5%;
  }

  img {
    width: 100%;
    margin-bottom: 2rem;
  }
}

// Cols

.o-half-col {
  width: 50%;
  overflow: hidden;
  float: left;
}

.o-quarter-col {
  float: left;
  width: 25%;
  overflow: hidden;
}

.col img {
  margin-bottom: calc(var(--gutter) / 2);
}


// Borders

.o-border-bottom {
  border-bottom: 1px solid #ddd;
}

.o-border-top {
  border-top: 1px solid #ddd;
}

.o-layout--sample {
  .col > div {
    padding: 16px 32px;
    background: #f5f5f5;
  }
}

// Products

.o-row--product {

  .col {
    display: grid;
    grid-gap: var(--gutter);
    justify-content: center;
    grid-template-columns: repeat(auto-fit, minmax(299px, auto));
  }
}
