/* ==========================================================================
Components
  > Alerts
========================================================================== */

.c-alert {
  position: relative;
  background: var(--color--secondary);
  color: #fff;
  text-align: center;
  padding: 6px 0;

  // Desktop

  &.c-alert--desktop {
    display: none;

    @include bp-large {
      display: block;
      height: var(--alert--h--desktop);
      overflow: hidden;

      &.u-bg-color--primary--charcoal {
        border-bottom: 1px solid #000;
      }

      .c-alert__title,
      .c-alert__subtext {
        max-width: 48%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
  }

  // Mobile

  &.c-alert--mobile {

    @include bp-large {
      display: none;
    }
  }

  .o-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }

  // Media

  .c-alert__media {
    flex-shrink: 0;
    width: 64px;
    margin-right: 16px;
    text-align: right;

     figure {
       @include fluid-aspect(16 9);
       margin: 0;
     }
  }

  // Title

  .c-alert__title {
    font-size: 16px;
    color: #fff;
    font-weight: 500;
    line-height: 24px;
    margin-right: 16px;
    margin-bottom: 0;
  }

  p {
    margin-bottom: 0;
  }

  // Subtext

  .c-alert__subtext {
    margin-right: 16px;
  }

  // Link

  a {
    color: #fff;
    text-decoration: underline;
  }

  // Close
  
  .c-btn--close__alert {
    height: 100%;
  }
}
