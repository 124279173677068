/* ==========================================================================
Settings
  > Global
========================================================================== */

:root {

  @include bp-medium {
    --header--height: 80px;
    --spacer: 32px;
  }

  @include bp-large {
    --header--height: auto;
    --padding--wrapper: 56px;
    --spacer: 40px;
  }
}
