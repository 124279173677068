/* ==========================================================================
   #CTA BANNER
   ========================================================================== */

.c-cta-banner {
  @include clearfix;
  text-align: center;

  @include bp-medium-large {
    text-align: left;
  }

  p {
    margin-top: 24px;
    color: #fff;
    font-size: 24px;
    line-height: 30px;

    @include bp-medium-large {
      width: 70%;
      float: left;
      font-size: 28px;
      font-weight: 500;
      margin: 8px 0 0 0;
    }
  }

  a {
    color: #fff;
    margin-top: 16px;
    margin-bottom: 32px;
    border: 0;

    @include bp-medium-large {
      margin-top: 0;
      margin-bottom: 0;
      min-width: 185px;
      float: right;
    }

    &:hover {
      border: 0;
    }
  }
}
