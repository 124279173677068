/* ==========================================================================
   #BREADCRUMBS
   ========================================================================== */

.c-breadcrumb {
  padding: 0.8rem 0;
  background: #F4F5F9;

  ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    float: left;
    display: none;

    @include bp-medium {
      display: block;
    }

    @include bp-max-large {
      &.c-breadcrumb--alt {
        display: block;

        li:not(.c-page--current),
        span {
          display: none;
        }
      }
    }

    li {
      display: inline-block;
      padding: 0 0.5rem 0 0;
      position: relative;
      font-size: 0.875rem;

      a {
        color: var(--color--primary--charcoal);
        text-decoration: none;

        &:hover {
          @include t-link__hover;
        }
      }
    }

    // Toolbar

    &.c-toolbar {
      float: right;
      display: block;
      border-top: none;
      width: auto;
      display: block;
      margin: 0;
      padding: 0;

      > li {
        cursor: pointer;
        padding: 0;
        margin: 0 0 0 12px;
        font-size: 0.875rem;
      }

      .moon-share, .moon-print {
        position: relative;
        top: 2px;
        margin-left: 4px;
        font-size: 16px;
        color: $color-primary;
      }

      .moon-share {
        font-size: 14px;
      }
    }
  }

  .moon-arrow-right {
    font-size: 12px;
    color: $color-medium-grey;
    margin-right: 0.5rem;
  }
}

// Print / Share

.c-print, .c-share {

  button {
    background: 0 0;
    border: 0;
    padding: 0;
    color: #343434;
    cursor: pointer;

    &:hover {
      color: $color-primary;
    }

    .c-btn__text {
      display: none;

      @include bp-medium {
        display: inline-block;
      }
    }
  }
}

// Share

.c-share {

  > ul {
    display: none;
    position: absolute;
    top: 39px;
    left: -12px;
    margin: 0 auto;
    background: $color-primary;
    width: 50px;
    padding: 15px 0 10px 0;
    text-align: center;
    transition: all 0.3s ease-in-out;
    transform: translateY(-10px);
    z-index: 11;

    @include bp-medium {
      left: 10px;
    }

    &:after {
      content: "";
      position: absolute;
      top: -7px;
      left: 15px;
      width: 0;
      height: 0;
      border-style: solid;
      border-width: 0 10px 10px 10px;
      border-color: transparent transparent $color-primary transparent;
    }

    > li {
      text-align: center;
      padding: 0;
      width: 100%;
      display: block;
      margin-bottom: 3px;

      > a {
        color: #fff;
      }
    }
  }

  &.active > ul {
    display: block;
  }
}
