/* ==========================================================================
   #THUMB
   ========================================================================== */

.c-thumb {
  position: relative;
  margin-bottom: 1rem;
  overflow: hidden;

  img {
    width: 100%;
    height: auto;
    transition: all 0.3s ease-in-out;
  }

  a::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    bottom: 0;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#000000+0,000000+100&0+0,0.65+100 */
    background: -moz-linear-gradient(top, rgba(0,0,0,0) 0%, rgba(0,0,0,0.75) 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom, rgba(0,0,0,0) 0%,rgba(0,0,0,0.75) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00000000', endColorstr='#a7000000',GradientType=0 ); /* IE6-9 */
  }

  &:hover {

    img {
      transform: scale(1.1);
    }
  }

  p {
    margin-bottom: 0;
    position: absolute;
    display: block;
    bottom: 1rem;
    left: 1rem;
    right:1rem;
    width: 100%;
    color: #fff;
    z-index: 2;

    span {
      font-family: $font-body;
      display: block;
      font-size: 0.875rem;
    }
  }
}
