/* ==========================================================================
   #LISTS
   ========================================================================== */

li {

  > ul,
  > ol {
    margin-bottom: 0;
    margin-left: 1rem;
    margin-top: 0.5rem;
  }
}

ol {
  li {
    margin-bottom: 1rem;
    padding-left: 16px;

    a {
      text-decoration: underline;
      color: $color-text;

      &:hover {
        color: #000;
      }
    }
  }
}

.unstyled-list {
  margin: 0 0 1.5rem 0;
  padding: 0;

  li {
    list-style-type: none;
  }
}
