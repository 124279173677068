/* ==========================================================================
   #Events
   ========================================================================== */

.c-grid-list {
  margin-top: 1rem;

  .c-grid-item {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    overflow: hidden;
    margin-bottom: 1rem;

    .c-thumb {
      margin-bottom: 0;
    }

    .c-grid-item--content {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 5px;
      border: 1px solid $color-light-grey;
      border-top: 0;
      padding: 1rem;
      height: 7rem;
      background: none;

      h3 {
        font-size: 1.1rem;
        margin-bottom: 0.5rem;

        a {
          color: $color-secondary;
        }
      }

      p {
        font-size: 0.875rem;
        margin-bottom: 0;

        a {
          color: #222;
        }
      }
    }
  }
}
