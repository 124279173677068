/* ==========================================================================
   #SINGLE CONTENT PODS
   ========================================================================== */

   .c-single-content-pod {
     padding: 2rem;
     border: 1px solid #ccc;

    .label {
      display: inline-block;
      background-color: var(--color--secondary);
      font-size: 14px;
      color: #fff;
      padding: 0.2rem 0.6rem;
      text-transform: uppercase;
      font-weight: bold;
      margin-bottom:1rem;
    }

    h2, h3 {
      margin-bottom: 1rem;
    }

    hr {
      margin-top: 2rem;
      background: #ccc;
      border: 0;
      height: 1px;
      display: block;
    }

    blockquote  {
      color: #777;
    }

   }
