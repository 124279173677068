/* ==========================================================================
Objects
  > Container
========================================================================== */

.o-container {
  @include clearfix;
  max-width: calc(1280px + var(--gutter) * 2);
  margin: auto;
  padding: 0 var(--gutter);
  position: relative;
}

@media ( min-width: 375px ) and ( max-width: 767px) {
  .o-container--alt {
    padding: 0 var(--gutter--alt);
  }
}

.o-narrower-fix {
  @include clearfix;
  max-width: 80em;
  margin: auto;
  position: relative;

  @include bp-large {
    padding: 0 10rem 0 0;
  }

  @include bp-xlarge {
    padding: 0 10rem 0 0;
  }
}

.o-narrowest-fix {
  @include clearfix;
  max-width: 80em;
  margin: auto;
  position: relative;

  @include bp-large {
    padding: 0 20rem 0 0;
  }

  @include bp-xlarge {
    padding: 0 20rem 0 0;
  }
}
