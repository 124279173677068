/* ==========================================================================
   Tools
     > UI -- Global
   ========================================================================== */

/*
  Shadow & Border Radius
*/

@mixin t-ui--global {
  @include shadow(3);
  border-radius: var(--global--radius);
  border: 1px solid var(--color--grey--medium);
}
