/* ==========================================================================
   #FOOTER
   ========================================================================== */

.c-footer {
  background: #F3F3F3;
  position: relative;

  h2, h3 {
    color: $color-tertiary;
    font-size: 22px;
    font-weight: 500;
    position: relative;

    @include bp-medium {
      font-size: 26px;
    }

    button {
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      font-size: 16px;
      background: transparent;
      border: none;
      cursor: pointer;
      color: var(--color--primary);

      @include bp-medium {
        display: none;
      }
    }

    a {
      font-weight: 500;
      color: #444;
      text-decoration: none;

      @include bp-medium {
        font-weight: 400;
        color: var(--color--primary);
      }
    }

    &.active span::before {
      content: "\ed8c";
    }
  }

  h3 {
    margin: 0;
  }

  .footer-menu ul {
    display: none;
    margin-top: 16px;
    margin-bottom: 0;

    @include bp-medium {
      display: block;
      margin-bottom: 0;
    }

    &.active {
      display: block;
    }
  }

  ul {
    margin: 0;
    padding: 0;

    @include bp-medium {
      display: block;
      border-bottom: 0;
    }

    li {
      margin-bottom: 12px;
      list-style-type: none;

      a {
        color: #444;
        font-family: $font-body;
        text-decoration: none;

        &:hover {
          color: $color-primary;
          text-decoration: underline;
        }
      }
    }

    &.c-footer-social {
      font-size: 0px; // Removes whitespace
      transform: translateX(-12px);
      margin-bottom: 32px;
      padding: 0;

      li {
        display: inline-block;
        margin: 0;

        a {
          font-size: 25px;
          height: 40px;
          width: 40px;
          display: block;
          line-height: 40px;
          text-align: center;
          color: var(--color--primary);
          transition: all 0.2s ease-in-out;

          @include bp-medium {
            font-size: 20px;
          }

          &:hover {
            color: var(--color--primary--apa);
            text-decoration: none !important;
          }
        }
      }
    }
  }
}

  // Secondary

  .c-footer--secondary {
    font-size: 16px;

    ul {
      margin-bottom: 24px;

      &.c-contact {
        margin-bottom: 0;
      }
    }

    li {
      margin-bottom: 0;
    }
  }

  // Bottom Footer

  .footer-bottom {
    border-top: 1px solid #DFDFE8;
    padding-top: 2.5rem;
    margin-top: 2rem;

    p {
      margin-bottom: 0;
    }

    .c-footer-logo {
      max-width: 190px;
      margin-bottom: 0.5rem;
    }
  }

  .terms {
    margin: 0 !important;
    padding: 0 !important;
  }

    .terms li {
      display: inline-block;
      font-size: 16px;
    }

      .terms li:first-child {
        margin-right: 1rem;
      }

      .terms li a {
        padding: 0rem 0.5rem;
        border-right: 1px solid #ccc;
      }

      .terms li:last-child a {
        border-right: 0;
      }

  .c-contact {
    font-size: 16px;

    span {
      margin-right: 4px;
      color: $color-primary;

      &:before {
        vertical-align: middle;
      }
    }
  }
