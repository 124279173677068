
:root {
  --c-ada-carousel-height: 300px;
}


/**
  Container
*/
.c-ada-carousel {
  position: relative;
}


/**
  Controls - pause/play, previous, and next buttons
*/
.controls {
}

  /* All buttons */
  .controls button {
    background: none;
    color: rgba(0,0,0,0.7);
    border: 0;
    cursor: pointer;
    transition: all 0.2s linear;
  }

    .controls button:focus {
      outline: none;
      background-color: rgba(0,0,0,0.8);
      color: rgba(255,255,255,1);
    }

  /* Pause/play button */
  .controls .rotation-button {
    position: absolute;
    bottom: 5px;
    left: 5px;
    padding: 10px 15px;
    z-index: 1;
    font-size: 20px;
    border-radius: 5px;
  }

    .controls .rotation-button .pause-container,
    .controls .rotation-button .resume-container {
      display: none;
    }

  /* Previous slide button */
  .controls .previous-button {
    position: absolute;
    left: 0;
    top: 45%;
    padding: 5px 15px;
    z-index: 1;
    font-size: 30px;
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
    border-left: 0;
  }

  /* Next slide button */
  .controls .next-button {
    position: absolute;
    right: 0;
    top: 45%;
    padding: 5px 15px;
    z-index: 1;
    font-size: 30px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    border-right: 0;
  }


/**
  Slides
*/
.slides {
  z-index: 0;
  white-space: nowrap;
  height: auto;
  overflow: hidden;
  display: flex;
}

  .slides .slide {
    flex: 0 0 100%;
    position: relative;
  }

    .slides .slide[aria-hidden="true"] {
      visibility: hidden;
    }

    .slides .slide .background-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }


    /**
  Slide 2 content
*/
    .slides .slide .content {
      position: absolute;
      top: 20px;
      left: 15%;
      max-width: 400px;
      width: 50%;
      max-height: 70%;
      padding: 15px;
      white-space: normal;
      color: white;
      background-color: royalblue;
      box-shadow: 3px 3px 5px 0 rgba(0,0,0,0.3);
    }

      .slides .slide .content h2 {
        margin: 0;
        font-size: 1.7rem;
      }

      .slides .slide .content p {
        margin: 10px 0 20px 0;
      }

      .slides .slide .content .cta-link {
        display: inline-block;
        padding: 5px 10px;
        background-color: white;
        color: rgba(0,0,0,0.7);
        text-decoration: none;
        font-weight: 800;
        box-shadow: 3px 3px 0 0 rgba(0,0,0,0.3);
      }

        .slides .slide .content .cta-link:focus,
        .slides .slide .content .cta-link:hover {
          text-decoration: underline;
          color: black;
        }

        .slides .slide .content .cta-link:focus {
          outline: 3px solid rgba(0,0,0,1);
        }


/**
  Slide dot navigation
*/
.navigation {
  position: absolute;
  bottom: 25px;
  width: 100%;
  text-align: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

  .navigation .slide-dot {
    display: inline;
  }

    .navigation .slide-dot:not(:last-of-type) {
      margin-right: 5px;
    }

  .navigation .slide-dot-button {
    background-color: rgba(0,0,0,0.3);
    border: 0;
    width: 15px;
    height: 15px;
    border-radius: 15px;
    cursor: pointer;
  }

    .navigation .slide-dot-button:focus {
      box-shadow: 0 0 0 1px white, 0 0 0 4px hsl(204, 86%, 53%);
      outline: none;
    }

  .navigation .slide-dot .slide-dot-button[aria-current="true"] {
    background-color: rgba(0,0,0,1);
  }


/**
  Utilities
*/
.is-visible {
  display: block !important;
}

// Custom Theme

.c-ada-carousel {

  .slides {

    .slide {
      width: 100%;
      height: auto;

      .slide-wrapper {
        width: 100%;
        font-size: 16px;
        font-size: 1rem;
        white-space: normal;
        display: inline-block;
        vertical-align: top;
      }

      .slide-inner {
        padding: 3rem;
        border: 1px solid #ddd;
        @include clearfix();
      }

      .slide-content {
        width: 100%;

        @include bp-medium {
          width: 70%;
          float: left;
        }
      }

      .slide-thumb {
        display: none;

        @include bp-medium {
          display: block;
          width: 25%;
          margin-left: 5%;
          float: left;
        }

        img {
          width: 100%;
          height: auto;
        }
      }
    }
  }
}
