/* ==========================================================================
   #CONNECT
   ========================================================================== */

.c-connect {
  margin-bottom: var(--gutter);
  padding: 2rem;
  background: var(--color--blue--light);

  ul {
    margin: 0;

    li {
      line-height: 1.2;
      margin-bottom: 12px;

      span {
        position: relative;
        top: 1px;
        margin-right: 1rem;
        float: left;
        margin-bottom: 4px;
        color: $color-blue;
      }

      a {
        overflow: auto;
        color: $color-text;
        text-decoration: none;

        &:hover {
          color: #000;
        }
      }
    }
  }
}
