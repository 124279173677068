/* ==========================================================================
   #News
   ========================================================================== */

.c-news-detail {

  .date {
    font-size: 1.4rem;
    margin-bottom: 0.5rem;
  }

  .author-img img {
    border-radius: 50px;
    height: 65px;
    width: 65px;
  }
}

.c-related {
  padding-top: 3rem;
  margin-top: 3rem;
  border-top: 1px solid $color-light-grey;
}
