/* ==========================================================================
   #PILL
   ========================================================================== */

.c-pill {
  display: inline-block;
  background: $color-primary;
  color: #fff;
  padding: 0 0.5rem;
  height: 25px;
  line-height: 25px;
  font-size: 12px;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
