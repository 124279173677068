/* ==========================================================================
   #HALF POD
   ========================================================================== */

   .c-half-pod {
    width: 100%;
    background: $color-very-light-grey;
  
    .c-half-pod--desc {
      width: 100%;
      padding: 2rem;
  
      @include bp-large {
        width: 50%;
        padding: 2rem;
      }
  
      @include bp-xlarge {
        width: 50%;
        padding: 4rem;
      }

      small {
        display: block;
        margin-top: 0.5rem;
      }
    }

    &.tight .c-half-pod--desc {

      @include bp-xlarge {
        width: 50%;
        padding: 2.5rem;
      }
    }
  
    .c-half-pod--img {
      width: 100%;
  
      @include bp-large {
      width: 50%;
      }
  
      background-size: cover;
      background-repeat: no-repeat;
    }
  
    &.image-right {
      clear: both;
      width: 100%;
      position: relative;
      overflow: hidden;
  
      .c-half-pod--desc {
        width: 100%;
  
        @include bp-small-medium {
          width: 50%;
        }
      }

      .c-half-pod--img {
        width: 100%;
        height: 300px;
        position: relative;
  
        @include bp-small-medium {
          position: absolute;
          top: 0;
          bottom: 0;
          right: 0;
          height: 100%;
          width: 50%;
        }
  
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-style: solid;
          border-width:  0 15px 15px 15px;
          border-color: transparent transparent $color-very-light-grey transparent;
        }
  
        @include bp-small-medium {
  
          &::after {
            content: "";
            position: absolute;
            top: 45%;
            right: initial;
            left: 0;
            bottom: auto;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 0 15px 15px;
            border-color: transparent transparent transparent $color-very-light-grey;
          }
        }
      }
    }
  
    &.image-left {
      clear: both;
      width: 100%;
      position: relative;
      overflow: hidden;
  
      .c-half-pod--desc {
        width: 100%;
  
        @include bp-small-medium {
          margin-left: 50%; width: 50%;
        }
      }
      .c-half-pod--img {
        width: 100%;
        height: 300px;
        position: relative;
  
        @include bp-small-medium {
          position: absolute;
          top: 0;
          left: 0;
          height: 100%;
          width: 50%;
        }
  
        &::after {
          content: "";
          position: absolute;
          bottom: 0;
          right: 0;
          left: 0;
          margin: auto;
          width: 0;
          height: 0;
          border-style: solid;
          border-width: 0 15px 15px 15px;
          border-color: transparent transparent $color-very-light-grey transparent;
        }
  
        @include bp-small-medium {
  
          &::after {
            content: "";
            position: absolute;
            top: 45%;
            bottom: auto;
            left: initial;
            right: 0;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 15px 15px 15px 0;
            border-color: transparent $color-very-light-grey transparent transparent;
          }
        }
      }
    }

    
    &.white {
      background: #fff;

      .c-half-pod--img::after {
        border-color: transparent transparent #fff transparent!important;

        @include bp-small-medium {
          display: none;
        }
      }
    }
  }
