/* ==========================================================================
   #HEADINGS
   ========================================================================== */


/* Col Header */

.c-col-header {
  background: $color-tertiary;
  color: $color-white;
  font-weight: normal;
  padding: 0.7rem 1rem;
  font-size: 1.3rem;
}

/* Col Header - FAQ */

@include bp-medium {
  .c-col-header__faq {
    background-image: url('../assets/images/icon-help@2x.png');
    background-size: 32px 32px;
    background-repeat: no-repeat;
    background-position-x: 24px;
    background-position-y: 16px;
    padding-left: 70px;
    background-position-y: 10px;
  }
}

@include bp-large {
  .c-col-header__faq {
    background-position-y: 14px;
  }
}

.c-heading--sidebar-nav {
  font-size: 18px;
  margin-bottom: 0;
  padding: 0 0 16px 24px;
}

.c-event--details {
  .h4 {
    @include t-heading--h5;
  }

  p {
    margin-bottom: 0;
  }
}

// Widgets

.c-heading--widget {
  max-width: calc(1280px + var(--gutter) * 2);
  margin: 0 auto;
  padding: 16px calc(var(--gutter) * 1) 0;
  font-size: 20px;
}

// Style Guide

.c-heading--style-guide {
  font-size: 20px;
  font-weight: 400;
}

// Bordered

.c-heading--borderd {
  border-bottom: 1px solid var(--color--grey--dark);
  padding-bottom: 8px;
  margin-bottom: 64px;
}