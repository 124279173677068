/* ==========================================================================
   #PAGINATION
   ========================================================================== */

// ul, li

.c-pagination {
  @include clearfix();
  width: auto;
  list-style: none;
  margin: 0;
  padding: 0;

  @include bp-large {
    margin-top: 16px;
  }

  > li {
    display: inline-block;
    margin: 0 0 8px 0;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #fff;
    text-align: center;
    border-radius: 50px;

    @include bp-medium {
      width: 48px;
      height: 48px;
      line-height: 48px;
      margin-right: 8px;
    }

    span.moon-arrow-left, span.moon-arrow-right {
      font-size: 0.8rem;
    }
  }
}

// a, active

.c-pagination {

  > li > a {
    width: 40px;
    height: 40px;
    line-height: 40px;
    text-align: center;
    display: block;
    text-decoration: none;
    color: $color-medium-grey;
    border-radius: 50px;

    @include bp-medium {
      width: 48px;
      height: 48px;
      line-height: 48px;
    }
  }
}

// active

.c-pagination .active {
  background: $color-primary;
  color: #fff;

  &:hover {
    background: $color-primary;
  }
}

// hover

.c-pagination > li > a:hover {
  color: #fff;
  background: $color-primary;
}
