/* ==========================================================================
   #Sidebar Widgets
   ========================================================================== */

// Widget Areas

.c-sidebar-widgets {
  display: block;
}


.c-sidebar-widget-text-image {
  border-radius: $global-radius;
  background: $color-very-light-grey;
  overflow: hidden;
  margin-top: 2rem;

  &.white .c-sidebar-widget-text-image--content {
    background: #fff;
    border: 1px solid #ddd;
  }

  .c-sidebar-widget-text-image--img {
    position: relative;
    height: 10rem;
    width: 100%;
    display: block;
    background-repeat: no-repeat;
    background-size: cover;
  }

  .c-sidebar-widget-text-image--content {
    padding: 1.5rem;

    h3 {
      font-size: 1.2rem;
      color: $color-text;
      margin-bottom: 0.5rem;
    }

    p {
      font-size: 0.875rem;
    }

    .c-btn {
      width: 100%;
    }
  }
}
