/* ==========================================================================
Utilities
  > Video
========================================================================== */

 .u-video--container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
  height: 100%;
}

.u-video--container iframe, .u-video--container object, .u-video--container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
