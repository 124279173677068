/* ==========================================================================
Utilities
  > Flex
========================================================================== */

// @m

@include bp-medium {

  .u-flex--m {
    display: flex;

    .col {
      margin-left: 0 !important;
    }

    .c-card {
      height: 100%;
    }
  }

  // Wrap

  .u-flex--wrap--m {
    flex-wrap: wrap;
  }
}
