/* ==========================================================================
   Tools
     > Content -- Fade
   ========================================================================== */

@mixin t-content--fade {

  position: relative;

  &:after {
    content: '';
    background: linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
  }
}
