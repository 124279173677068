/* ==========================================================================
Components
  > Swatches
========================================================================== */

.c-swatch {

  .c-swatch__item {
    display: flex;
    align-items: center;
    position: relative;

    .c-swatch__color {
      width: 80px;
      height: 56px;
      margin-right: 8px;
    }

    figcaption {
      font-size: 20px;

      span {
        position: absolute;
        bottom: 6px;
        left: 6px;
        color: #fff;
        font-size: 13px;
        font-weight: 500;
        line-height: 1;
      }
    }
  }
}