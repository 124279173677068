/* ==========================================================================
   #FEATURE BANNER
   ========================================================================== */

/// <reference path="../settings/_global.scss" />
/// <reference path="../tools/_breakpoints.scss" />

.c-feature-banner {
  padding: 5rem 0;

  .o-container {
    max-width: 65em;

    .col {
      padding: 0 3rem;
    }
  }

  @include bp-large {
    padding: 0;
  }

  .c-feature-banner__image {
    text-align: center;
    margin-bottom: 3rem;

    @include bp-large {
      text-align: left;
      margin-bottom: 0rem;
      float: left;
      width: 50%;
      padding: 5rem 0;
    }
  }

}

.c-feature-banner .c-feature-banner__content {
  text-align: center;

  @include bp-large {
      text-align: left;
      position: relative;
      width: 50%;
      position: absolute;
      height: 100%;
      right: 0;
      top: 0;
      bottom: 0;
    }

  .c-feature-banner__content--inner {

    @include bp-large {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      padding: 0 2rem 0 5rem;
      left: 0;
      right: 0;
    }
  }

  img {
    margin-bottom: 2rem;
  }

  .btns {
    margin-top: 2rem;

    a {
      margin: 0rem 1rem 1rem 1rem;
      @include bp-large {
        margin: 0rem 1rem 1rem 0rem;
      }
    }
  }
}

.c-feature-banner__image a {
  display: block;
  position: relative;

  &::before {

    @include bp-large {
      content: "";
      background: #fff;
      position: absolute;
      top: 10px;
      left: -10px;
      right: 10px;
      bottom: -10px;
      display: block;
      height: 100%;
      width: 100%;
      box-shadow: 0 7px 30px rgba(0,0,0,0.2);
    }
  }

  img {
  
    @include bp-large {
      width: 100%;
      height: auto;
      z-index: 2;
      position: relative;
    }
  }
}

// Image Right


.c-feature-banner.u-background-angle--large {

  .c-feature-banner__content {

    p {
      color: #fff;
    }

    h3 {
      color: #fff;
    }
  }
}

@include bp-large {

  .c-feature-banner.u-background-angle--large .c-feature-banner__image {
    float: right;
  }

  .c-feature-banner.u-background-angle--large .c-feature-banner__content {
    text-align: left;
    position: relative;
    width: 50%;
    position: absolute;
    height: 100%;
    right: auto;
    left: 0;
    top: 0;
    bottom: 0;

  }

}


// Buttons


.c-feature-banner.u-background-angle--small {

  .c-btn {
    background: $color-green;
    border-color: $color-green;
    color: #fff;

    &:nth-of-type(2) {
      border-color: $color-secondary-dark!important;
      background: none!important;
      color:  $color-secondary-dark!important;
    }
  }

  &.blue {
    .c-btn {
      background: $color-secondary-dark;
      border-color: $color-secondary-dark;
      color: #fff;
    }
  }
  &.red {
    .c-btn {
      background: $color-red;
      border-color: $color-red;
      color: #fff;
    }
  }
  &.purple {
    .c-btn {
      background: $color-purple;
      border-color: $color-purple;
      color: #fff;
    }
  }

}

.c-feature-banner.u-background-angle--large .c-btn {
  background: #fff;
  border-color: #fff;
  color: $color-secondary-dark;

  &:nth-of-type(2) {
    background: none;
    border-color: #fff;
    color: #fff;
  }
}
