
/* ==========================================================================
Utilities
  > Position
========================================================================== */

// WayPoints sticky

.stuck {
  position: fixed;
  top: 0;
  z-index: 2;
}

// Vertical - top

.u-pos--v-t {
  vertical-align: top;
}
