/* ==========================================================================
   #TABS
   ========================================================================== */

/**
  Tab button controls
*/
.tab {
  font-size: 18px;
  background: 0;
  border: 0;
  margin: 0 3px 0 0;
  padding: 16px 8px;
  color: #444;
  background-color: #fff;
  cursor: pointer;
}

.tablist {
  border-bottom: 1px solid #ddd;
}

  /** Active state */
  .tab:active {
    outline: none;
  }

  /** Selected state (tabpanel visible) */
  .tab[aria-selected="true"] {
    opacity: 1;
    border-bottom: 6px solid var(--color--secondary);
    margin-bottom: -1px;
  }

    .tab[aria-selected="true"]:focus {
      background: #f5f5f5;
      outline: none;
      text-decoration: none;
    }


/**
  Tab panels
*/
.tabpanel {
  display: none;
  background-color: #fff;
  padding: var(--gutter) 0;
}

  .tabpanel.is-visible {
    display: block;
  }

  .tabpanel h2 {
    margin-top: 0;
  }

