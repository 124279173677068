/* ==========================================================================
   #BUTTONS
   ========================================================================== */

.c-btn, .btn {
  font-family: $font-body;
  display: inline-block;
  border: 1px solid $color-primary;
  text-align: center;
  margin: 0;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  border-radius: $global-radius;
  text-decoration: none;
  background-color: $color-primary;
  color: $color-white;
  overflow: hidden;
  width: auto;
  padding: var(--padding--btn);
  height: 3rem;
  line-height: 3rem;
  font-weight: 500;
  text-transform: uppercase;
  white-space: nowrap;
  vertical-align: middle;

  &:hover {
    background: $color-primary-dark;
    border: 1px solid $color-primary-dark;
    color: #fff;
    text-decoration: none !important;
  }

  &:focus {
    outline: 2px dotted $color-primary;
  }

  .c-icon--s {
    font-size: 16px;
    position: relative;
    right: -8px;
  }

  .c-icon--l {
    font-size: 24px;
    position: relative;
    top: 4px;
    right: -12px;
  }
}

.c-btns {

  .c-btn {
    width: 100%;
    margin: 0.5rem 0;

    @include bp-small-medium {
      width: auto;
      margin: 0.5rem;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }
}

/* In-line */
.c-btn__inline {
  margin: 0 1em 1em 0;
}

/* Orange */
.c-btn--orange {
  background: $color-secondary;
  border: 1px solid $color-secondary;
  color: #fff;

  &:hover {
    background: darken( $color-secondary, 10% );
    border: 1px solid darken( $color-secondary, 10% );
    color: #fff;
  }
}

/* Red */
.c-btn--red {
  background: $color-red;
  border: 1px solid $color-red;
  color: #fff;

  &:hover {
    background: darken( $color-red, 10% );
    border: 1px solid darken( $color-red, 10% );
    color: #fff;
  }
}

/* Green */

.c-btn--green {
  background: $color-green;
  border: 1px solid $color-green;
  color: #fff;

  &:hover {
    background: darken( $color-green, 10% );
    border: 1px solid darken( $color-green, 10% );
    color: #fff;
  }
}

/* Blue */

.c-btn--blue {
  background: $color-blue;
  border: 1px solid $color-blue;
  color: #fff;

  &:hover {
    background: darken( $color-blue, 10% );
    border: 1px solid darken( $color-blue, 10% );
    color: #fff;
  }
}

/* Outline
   ========================================================================== */

   .c-btn--outline {
    background: transparent;
    border: 1px solid $color-primary;
    color: $color-primary;
  
    &:hover {
      background: $color-primary;
      border: 1px solid $color-primary;
      color: #fff;
    }
  }

  .c-btn--outline--blue {
    background: transparent;
    border: 1px solid $color-light-medium-grey;
    color: $color-blue;
  
    &:hover {
      background: $color-blue;
      border: 1px solid $color-blue;
      color: #fff;
    }
  }

.c-btn--outline--light-blue {
  background: transparent;
  border: 1px solid $color-light-medium-grey;
  color: $color-light-blue;

  &:hover {
    background: $color-light-blue;
    border: 1px solid $color-light-blue;
    color: #fff;
  }
}


.c-btn--outline--green {
    background: transparent;
    border: 1px solid $color-light-medium-grey;
    color: $color-green;
  
    &:hover {
      background: $color-green;
      border: 1px solid $color-green;
      color: #fff;
    }
 }

.c-btn--outline--purple {
    background: transparent;
    border: 1px solid $color-light-medium-grey;
    color: $color-purple;
  
    &:hover {
      background: $color-purple;
      border: 1px solid $color-purple;
      color: #fff;
    }
 }

  .c-btn--outline--red {
    background: transparent;
    border: 1px solid $color-light-medium-grey;
    color: $color-red;
  
    &:hover {
      background: $color-red;
      border: 1px solid $color-red;
      color: #fff;
    }
  }

  .c-btn--outline--white {
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
  
    &:hover {
      background: #fff;
      border: 1px solid #fff;
      color: $color-secondary-dark;
      box-shadow: 0 5px 10px 0 rgba(0,0,0,0.25);
    }
  }

  .c-btn--outline--icon {
    border: 1px solid #ddd;
    background: #fff;
    color: $color-secondary;

    span {
      font-size: 1.1rem!important;
      margin: 0 0.5rem 0 0!important;
      vertical-align: middle;
      display: inline-block;
      color: $color-primary;
    }

    &:hover {
      color: #fff;

      span {
        color: #fff;
      }
    }
  }

/* Size variants
   ========================================================================== */

.c-btn--small {
  height: 40px;
  line-height: 40px;
  font-size: 0.875rem;
  padding: 0 1rem 0 1rem;
  font-weight: 600;
}

 .HiddenButton {
   display: none;
 }

 // Footer Button

 .c-footer__btn {
   border-top: 1px solid $color-light-medium-grey;
   padding-top: 3rem;
   margin-top: 3rem;
   margin-bottom: 3rem;
 }

 // Arrow Button

.c-btn--arrow {
  color: $color-primary;
  background: none;
  padding: 0;
  border: 0;
  font-size: 16px;
  position: relative;
  transition: 0.3s all ease-in-out;
  font-weight: normal;
  overflow: visible;
  white-space: normal;
  text-align: left;
  height: auto;
  line-height: 1.4;
  overflow-wrap: break-word;

  span {
    margin-left: 4px;
    transition: $global-transition;
  }

  &:hover {
    background: transparent;
    color: $color-secondary;
    border: none;

    span {
      color: $color-secondary;
      margin-left: 8px;
    }
  }
}

 // Extend

.c-btn--extend {
  width: 100%;
  max-width: 480px;
  margin: auto;
  display: block;
}

// No Case

.c-btn--case--none {
  text-transform: none;
}

// Weight - 400

.c-btn--weight--400 {
  font-weight: 400;
}

// Meta

.c-btn__meta {
  display: inline-block;
  height: 100%;
  border-left: 1px solid rgba(255,255,255,0.33);
  padding-left: 12px;
  margin-left: 12px;
}

// Icon

.c-btn__icon {
  display: inline-block;
  margin-right: 4px;
}

// Disabled

.c-filter__btn:disabled {
  opacity: 0;
  cursor: auto;
}

// Close

[class^="c-btn--close"] {
  @include t-reset--btn;
  width: var(--tap-target--size);
  height: var(--tap-target--size);
  line-height: var(--tap-target--size);
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  font-size: 24px;

  &:focus {
    background: var(--color--primary);
    color: #fff;
    outline: none;

    span {
      color: #fff;
    }
  }
}