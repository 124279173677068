/* ==========================================================================
Utilities
  > Color
========================================================================== */

// Bg Colours (Primary)

.u-bg-color--primary {
  background-color: var(--color--primary);
}

.u-bg-color--primary--blue--light {
  background-color: var(--color--primary--blue--light);
}

.u-bg-color--primary--apa {
  background-color: var(--color--primary--apa);
}

.u-bg-color--primary--navy {
  background-color: var(--color--primary--navy);
}

.u-bg-color--primary--charcoal {
  background-color: var(--color--primary--charcoal);
}

// Bg Colours (Secondary)

.u-bg-color--secondary {
  background-color: var(--color--secondary);
}

.u-bg-color--secondary--teal {
  background-color: var(--color--secondary--teal);
}


.u-bg-color--secondary--teal--light {
  background-color: var(--color--secondary--teal--light);
}

.u-bg-color--secondary--green {
  background-color: var(--color--secondary--green);
}

.u-bg-color--secondary--wine {
  background-color: var(--color--secondary--wine);
}

// Bg Colours (Tertiary)

.u-bg-color--tertiary,
.u-bg-color--tertiary--green {
  background-color: var(--color--tertiary--green);
}

.u-bg-color--tertiary--wine {
  background-color: var(--color--tertiary--wine);
}

.u-bg-color--tertiary--blue {
  background-color: var(--color--tertiary--blue);
}

.u-bg-color--tertiary--orange {
  background-color: var(--color--tertiary--orange);
}

.u-bg-color--tertiary--grey {
  background-color: var(--color--tertiary--grey);
}

.u-fill {
  background: $color-fill;
}

.u-bg-color--blue {
  background: $color-secondary;
}

.u-bg-color--med-grey {
  background: $color-medium-grey;
}

.u-bg-color--very-light-grey {
  background: $color-very-light-grey;
}

/* Text Colours */

.u-color--text {
  color: var(--color--text) !important;
}

.u-text-color--primary--apa {
  color: var(--color--primary--apa);
}

.u-text-color--white {
  color: $color-white!important;
}

.u-text-color--blue {
  color: $color-secondary!important;
}

.u-text-color--dark-blue {
  color: $color-secondary-dark!important;
}

.u-text-color--red {
  color: $color-primary!important;
}

.u-text-color--grey {
  color: $color-light-grey!important;
}

.u-color--primary {
  color: var(--color--primary) !important;
}

.u-color--primary--apa {
  color: var(--color--primary--apa) !important;
}

/* Font Weight*/

.u-font-weight--light {
  font-weight: 100;
}

.u-font-weight--normal {
  font-weight: normal;
}

.u-font-weight--bold {
  font-weight: 700;
}
