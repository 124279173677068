/* ==========================================================================
   #SHARE THIS
   ========================================================================== */

   .c-share-this {
     width: 100%;
     overflow: hidden;
     margin: 1rem 0 2.5rem 0;
     padding: 0 0 2rem 0;
     border-bottom: 1px solid #ddd;

     h3 {
       font-size: 1.2rem;
       margin-bottom: 0.5rem;
       font-weight: normal;
     }

     li {
       display: inline-block;
       margin-right: 5px;

       a {
         display: block;
         text-decoration: none;
         height: 35px;
         width: 35px;
         background: #ccc;
         line-height: 35px;
         text-align: center;
         border-radius: 50px;
         color: #fff;
         transition: all 0.2s ease-in-out;

         &.moon-facebook {background: $color-facebook;}
         &.moon-twitter {background: $color-twitter;}
         &.moon-linkedin {background: $color-linkedin;}
         &.moon-google-plus {background: $color-googleplus;}

         &:hover {
           transform: translate(0,-5px);
           box-shadow: 0 5px 5px 0 rgba(0,0,0,0.2);
         }
       }
     }
   }
