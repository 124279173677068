/* ==========================================================================
   #NAVIGATION - ADA MOBILE MENU
   ========================================================================== */

.nav {
  height: 52px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

/** Hamburger button */
.menu-toggle-button {
  background: none;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 20px;
}

.menu-toggle-button .fas {
  width: 20px;
}

/** Mobile menu */
#mobile-menu-modal {
  display: none;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 9;
}

/** Backdrop */
#mobile-menu-modal:before {
  display: block;
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0,0,0,0.6);
}

/** Close button */
.close-button {
  background: white;
  border: 0;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  margin-top: 0;
  position: absolute;
  left: 0;
  border-left: 1px solid #ddd;
  height: 53px;
  width: 49px;

  &:focus {
    background: var(--color--primary);
    color: #fff;
    border-left-color: var(--color--primary);

    span {
      color: #fff;
    }
  }
}

.close-button .fas {
  width: 20px;
}

.menu-container {
  position: relative;
  width: 0;
  height: 100%;
  z-index: 1;
  overflow: hidden;
  margin-top: 0;
  background-color: #fff;
}

.ada-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  z-index: 2;
  width: 100%;
  min-width: 250px;
}

.ada-menu li {
  width: 100%;
  border-bottom: 1px solid #ddd;
}

  .ada-menu li a,
  .ada-menu li button {
    position: relative;
    display: block;
    width: 100%;
    padding: 1rem;
    color: var(--color--primary);
    text-decoration: none;
    text-align: left;
    background: none;
    border: 0;
    cursor: pointer;
    line-height: 1.4rem;
    font-size: 1.125rem;

    &:hover, &:focus {
      background: var(--color--primary);
      color: #fff;
    }
  }

.ada-menu li button .moon-chevron-right {
  position: absolute;
  top: 50%;
  right: 15px;
  transform: translateY(-50%);
  font-size: 16px;
}

/** Back button on level 1 menus */

.ada-menu li button.back-button {
  background-color: #eee;
  color: #444;

  &:focus, &:hover {
    color: #fff;
    background: #444;
  }
}

.ada-menu li button.back-button .moon-chevron-left {
  margin-right: 10px;
  font-size: 12px;
}

/** Level 0 (top level) menu */
.menu-level-0 {
  position: absolute;
  left: 0;
  width: 100%;
}

/** Level 1 menu */
.menu-level-1 {
  position: absolute;
  left: 250px;
  width: 100%;
}

.menu-level-0 .ada-menu {
  display: block;
}

.menu-level-1 .ada-menu {
  display: none;
}

/** Level 1 menu */
.menu-level-2 {
  position: absolute;
  left: 250px;
  width: 100%;
}

.menu-level-2 .ada-menu {
  display: none;
}
