/* ==========================================================================
Components
  > Article
    > Meta
========================================================================== */

.c-article__meta {

  font-size: 0px; // Remove whitespace
  margin-bottom: 6px;

  .c-meta--highlight {
    font-size: 14px;
    font-weight: 500;
    margin: 0 10px 10px 0;
    background: var(--color--primary);
    display: inline-block;
    color: #fff;
    padding: 2px 8px;
  }
}
