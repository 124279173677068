/* ==========================================================================
   #SPACING
   @todo generate more generic classes and sizes
   ========================================================================== */

/* ====== PADDING ====== */

$utility-spacing: 1em;

/* General */

.u-padding {padding: $utility-spacing;}
.u-padding-right {padding-right: $utility-spacing;}
.u-padding-left {padding-left: $utility-spacing;}

.u-padding-0 {padding: 0 !important; }
.u-padding-right-0 {padding-right: 0 !important;}
.u-padding-left-0 {padding-left: 0 !important;}

/* Padding Top / Bottom */

.u-padding-top-bottom-100 {padding-top: 100px !important; padding-bottom: 100px !important;}
.u-padding-top-bottom-80 {padding-top: 80px !important; padding-bottom: 80px !important;}
.u-padding-top-bottom-60 {padding-top: 60px !important; padding-bottom: 60px !important;}
.u-padding-top-bottom-50 {padding-top: 50px !important; padding-bottom: 50px !important;}
.u-padding-top-bottom-40 {padding-top: 40px !important; padding-bottom: 40px !important;}
.u-padding-top-bottom-30 {padding-top: 30px !important; padding-bottom: 30px !important;}
.u-padding-top-bottom-20 {padding-top: 20px !important; padding-bottom: 20px !important;}
.u-padding-top-bottom-10 {padding-top: 10px !important; padding-bottom: 10px !important;}

.u-padding-top-bottom {padding: $utility-spacing 0 !important;}
.u-padding-top-bottom-sm {padding-top: 0.5rem !important; padding-bottom: 0.5rem !important;}
.u-padding-top-bottom-lg {padding-top: 3rem !important; padding-bottom: 2rem !important;}
.u-padding-top-bottom-xl {padding-top: 5rem !important; padding-bottom: 4rem !important;}

/* Padding Top */

.u-padding-top-100 {padding-top: 100px !important;}
.u-padding-top-80 {padding-top: 80px !important;}
.u-padding-top-60 {padding-top: 60px !important;}
.u-padding-top-50 {padding-top: 50px !important;}
.u-padding-top-40 {padding-top: 40px !important;}
.u-padding-top-30 {padding-top: 30px !important;}
.u-padding-top-20 {padding-top: 20px !important;}
.u-padding-top-10 {padding-top: 10px !important;}
.u-padding-top {padding-top: $utility-spacing !important;}
.u-padding-top-0 {padding-top: 0 !important;}

.u-padding-top {padding-top: $utility-spacing !important;}
.u-padding-top-sm {padding-top: 0.5rem !important;}

@include bp-medium {
  .u-padding-top-md {
    padding-top: 50px !important;
  }

}
@include bp-large {
  .u-padding-top-lg {padding-top: var(--padding--wrapper);}
}
.u-padding-top-xl {padding-top: 5rem !important;}

/* Padding Bottom */

.u-padding-bottom-100 {padding-bottom: 100px !important;}
.u-padding-bottom-60 {padding-bottom: 60px !important;}
.u-padding-bottom-50 {padding-bottom: 50px !important;}
.u-padding-bottom-40 {padding-bottom: 40px !important;}
.u-padding-bottom-30 {padding-bottom: 30px !important;}
.u-padding-bottom-20 {padding-bottom: 20px !important;}
.u-padding-bottom-10 {padding-bottom: 10px !important;}
.u-padding-bottom-0 {padding-bottom: 0 !important;}

.u-padding-bottom {padding-bottom: $utility-spacing !important;}
.u-padding-bottom-sm {padding-bottom: 0.5rem !important;}
.u-padding-bottom-lg {padding-bottom: 3rem !important;}
.u-padding-bottom-xl {padding-bottom: 5rem !important;}

/* ====== MARGIN ====== */

/* General */

.u-margin {margin: $utility-spacing;}
.u-margin-right {margin-right: $utility-spacing;}
.u-margin-left {margin-left: $utility-spacing;}

.u-margin-0 {margin: 0 !important;}
.u-margin-right-0 {margin-right: 0 !important;}
.u-margin-left-0 {margin-left: 0 !important;}

/* Margin Top / Bottom */

.u-margin-top-bottom-100 {margin-top: 100px !important; margin-bottom: 100px !important;}
.u-margin-top-bottom-80 {margin-top: 80px !important; margin-bottom: 80px !important;}
.u-margin-top-bottom-70 {margin-top: 70px !important; margin-bottom: 70px !important;}
.u-margin-top-bottom-60 {margin-top: 60px !important; margin-bottom: 60px !important;}
.u-margin-top-bottom-50 {margin-top: 50px !important; margin-bottom: 50px !important;}
.u-margin-top-bottom-40 {margin-top: 40px !important; margin-bottom: 40px !important;}
.u-margin-top-bottom-30 {margin-top: 30px !important; margin-bottom: 30px !important;}
.u-margin-top-bottom-20 {margin-top: 20px !important; margin-bottom: 20px !important;}
.u-margin-top-bottom-10 {margin-top: 10px !important; margin-bottom: 10px !important;}

.u-margin-top-bottom {margin: $utility-spacing 0 !important;}
.u-margin-top-bottom-sm {margin-top: 0.5rem !important; margin-bottom: 0.5rem !important;}
.u-margin-top-bottom-lg {margin-top: 3rem !important; margin-bottom: 3rem !important;}
.u-margin-top-bottom-xl {margin-top: 5rem !important; margin-bottom: 5rem !important;}

/* Margin Top */

.u-margin-top-100 {margin-top: 100px !important;}
.u-margin-top-80 {margin-top: 80px !important;}
.u-margin-top-70 {margin-top: 70px !important;}
.u-margin-top-60 {margin-top: 60px !important;}
.u-margin-top-50 {margin-top: 50px !important;}
.u-margin-top-40 {margin-top: 40px !important;}
.u-margin-top-30 {margin-top: 30px !important;}
.u-margin-top-20 {margin-top: 20px !important;}
.u-margin-top-10 {margin-top: 10px !important;}
.u-margin-top {margin-top: $utility-spacing!important;}
.u-margin-top-0 {margin-top: 0;}

.u-margin-top-sm {margin-top: 0.5rem !important;}
.u-margin-top-lg {margin-top: 3rem !important;}
.u-margin-top-xl {margin-top: 5rem !important;}

/* Margin Right */

.u-margin-right-10 {margin-right: 10px!important;}

/* Margin Bottom */

.u-margin-bottom-100 {margin-bottom: 100px !important;}
.u-margin-bottom-80 {margin-bottom: 80px !important;}
.u-margin-bottom-70 {margin-bottom: 70px !important;}
.u-margin-bottom-60 {margin-bottom: 60px !important;}
.u-margin-bottom-50 {margin-bottom: 50px !important;}
.u-margin-bottom-40 {margin-bottom: 40px !important;}
.u-margin-bottom-30 {margin-bottom: 30px !important;}
.u-margin-bottom-20 {margin-bottom: 20px !important;}
.u-margin-bottom-10 {margin-bottom: 10px !important;}
.u-margin-bottom {margin-bottom: $utility-spacing !important;}
.u-margin-bottom-0 {margin-bottom: 0 !important;}

.u-margin-bottom-sm {margin-bottom: 0.5rem !important;}
.u-margin-bottom-lg {margin-bottom: 3rem !important;}
.u-margin-bottom-xl {margin-bottom: 5rem !important;}

.u-mb--half {
  margin-bottom: 4px !important;
}

.u-mt--22 {
  margin-top: 22px;
}

// Gutter

.u-mt--gutter {
  margin-top: var(--gutter);
}

.u-mb--gutter {
  margin-bottom: var(--gutter);
}

// @m

@include bp-medium {

  .u-mb--0--m {
    margin-bottom: 0 !important;
  }
}

// @ml

@include bp-medium-large {

  .u-mt--2--ml {
    margin-top: 16px !important;
  }

  .u-mb--0--ml {
    margin-bottom: 0px !important;
  }

  .u-mb--2--ml {
    margin-bottom: 16px !important;
  }

  .u-mb--3--ml {
    margin-bottom: 24px !important;
  }
}

// @l

@include bp-large {
  .u-mb--gutter--l {
    margin-bottom: var(--gutter) !important;
  }

  .u-mb--0--l {
    margin-bottom: 0 !important;
  }

  .u-mt--2--l {
    margin-top: 16px !important;
  }

  .u-mt--5--l {
    margin-top: 40px !important;
  }

  .u-mb--3--l {
    margin-bottom: 24px !important;
  }

  .u-mb--5--l {
    margin-bottom: 40px !important;
  }

  .u-mb--6--l {
    margin-bottom: 48px !important;
  }

  .u-p--3--h--l {
    padding: 0 24px;
  }
}

// @xl

@include bp-xlarge {

  .u-mb--0--xl {
    margin-bottom: 0 !important;
  }
}

// Max @s

@include bp-max-small {
  .u-mb--1--max--s {
    margin-bottom: 8px;
  }
}

// Max @sm

@include bp-max-small-medium {
  .u-margin-bottom--for-max-s-m {
    margin-bottom: $gutter / 2;
  }
}

// Max @m

@include bp-max-medium {
  .u-p--0--max--m {
    padding-top: 0;
  }

  .u-mb--0--max--m {
    margin-bottom: 0 !important;
  }
}

// Max @ml

@include bp-max-medium-large {
  .u-mb--0--max--ml {
    margin-bottom: 0 !important;
  }

  .u-mb--5--max--ml {
    margin-bottom: 40px !important;
  }
}

// Max @l

@include bp-max-large {

  .u-mb--0--max--l {
    margin-bottom: 0 !important;
  }

  .u-mb--2--max--l {
    margin-bottom: 16px !important;
  }

  .u-mb--6--max--l {
    margin-bottom: 48px !important;
  }
}


// Gutter Adjust
.u-margin--gutter-adjust {
  margin-left: -$gutter / 2;
  margin-right: -$gutter / 2;
}
// Overflow Hidden
.u-overflowhidden {
  overflow: hidden;
}

// >=@l - <@xl

@media ( min-width: 1024px ) and ( max-width: 1279px ) {
  .u-mt--gutter--l--less-xl {
    margin-top: var(--gutter);
  }
}

// Spacer

.u-mt--spacer {
  margin-top: var(--spacer);
}


.u-mb--spacer {
  margin-bottom: var(--spacer);
}
