/* ==========================================================================
Settings
  > Hamburger
========================================================================== */

$hamburger-padding-x           : 0 !default;
$hamburger-padding-y           : 0 !default;
$hamburger-layer-width         : 24px !default;
$hamburger-layer-height        : 2px !default;
$hamburger-layer-spacing       : 5px !default;
$hamburger-layer-color         : #343434 !default;
$hamburger-layer-border-radius : 0px !default;
$hamburger-hover-opacity       : 1 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;
