/* ==========================================================================
Utilities
  > Width
========================================================================== */



// Max

@include bp-max-small-medium {

  // 100%

  .u-width--100p--max-s-m {
    width: 100%;
  }
}

// 380px

.u-max-width-380 {
  width: 100%;
  max-width: $u-max-width-380;
}



@include bp-small-medium {

  // 60%

  .u-max-width--60p--for-s-m {
    width: 100%;
    max-width: $u-max-width-60p;
  }

  // 40% - gutter

  .u-max-width--40p-gutter--for-s-m {
    width: 100%;
    max-width: calc(#{$u-max-width-40p} - (#{$gutter} / 2));
  }
}

@include bp-large {

  // 1400px

  .u-max-width--1080 {
    max-width: $u-max-width-1080;
  }
}
