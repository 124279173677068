/* ==========================================================================
Utilities
  > Borders
========================================================================== */

.u-border {
  border: 1px solid $color-light-medium-grey;
}

.u-border--b {
  border-bottom: 1px solid $color-light-medium-grey;
}

// Max

@include bp-max-large {

  // Bottom

  .u-border--b--for-max-l {
    border-bottom: 1px solid $color-light-medium-grey;
  }
}

.u-b--l {
  border-left: 1px solid var(--color--grey--medium);
}

@include bp-large {

  // Right

  .u-border--r--for-l {
    border-right: 1px solid $color-light-medium-grey;
  }
}

// Bottom

.u-bb {
  border-bottom: 1px solid var(--color--grey--light);
}

// None

.u-b--none {
  border: none;
}

// Border bottom - @l border right

.u-bb--r--l {
  border-bottom: 1px solid rgba(144,157,185,0.33);

  @include bp-large {
    border-bottom: none;
    border-right: 1px solid rgba(144,157,185,0.33);
  }
}
