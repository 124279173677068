/* ==========================================================================
   #Sidebar
   ========================================================================== */

// Sidebar Nav

.c-sidebar-nav--mobile {
  display: block;
  margin-top: 2rem;
}

.c-sidebar-nav {
  margin-bottom: 0rem;
  display: block;

  @include bp-large {
    margin-bottom: 3rem;
  }

  .c-sidebar-nav--btn {
    width: 100%;
    border: 1px solid var(--color--grey--medium);
    background-color: #fff;
    text-align: left;
    padding: 0 60px 0 16px;
    height: 48px;
    line-height: 48px;
    cursor: pointer;
    font-size: 16px;
    position: relative;
    display: block;

    span {
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 0;
      width: 60px;
      height: 48px;
      line-height: 48px;
      text-align: center;
      font-size: 12px;
    }

    @include bp-large {
      display: none;
    }

    &.active {

      span:before {
        content: "\e912";
      }
    }
  }

  > ul {
    border: 1px solid var(--color--grey--medium);
    border-top: 0;
    transition: opacity 0.3s ease-in-out;
    margin-left: 0;
    margin-bottom: 0;
    padding: 1rem 0 1rem 0;
    display: none;

    &.active {
      display: block;

      @include bp-large {
        display: block;
      }
    }

    > li {
      position: relative;
      list-style-type: none;
      padding: 8px 32px;

      &.current {
        position: relative;

        button {
          display: none;
        }

        > ul {
          display: block;
        }
      }

      ul {
        margin-top: 16px;
        margin-left: 8px;

        li {
          list-style-type: none;
          margin-bottom: 8px;

          a {
            display: block;
            color: #444;
          }
        }
      }

      > a {
        color: #222;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;

        &:hover {
          text-decoration: none;
          color: $color-primary;
        }
      }

      button {
        @include t-reset--btn;
        position: absolute;
        right: 0;
        top: 0;
        font-size: 12px;
        text-decoration: none;
        height: 45px;
        width: 60px;
        line-height: 45px;
        text-align: center;
        cursor: pointer;

        @include bp-large {
          width: var(--tap-target--size);
        }

        &:hover {
          color: $color-primary;
        }
      }

      &.active button .moon-chevron-down::before {
        content: "\e912";
      }

      ul {
        display: none;

        li {
          font-size: 16px;
        }
      }

      &.active ul {
        display: block !important;
      }
    }

    &.active {
      height: auto;
    }

    @include bp-large {
      padding: 0;
      border-top: 0;
      border-right: 0;
      border-bottom: 0;
      display: block !important;
    }
  }

  // Content

  .c-sidebar-nav__content {
    display: none;
    padding: 1rem 0;
    border: 1px solid var(--color--grey--medium);
    border-top: none;

    &.active {
      display: block;
    }

    @include bp-large {
      display: block;
      padding: 0;
      border: none;
      border-left: 1px solid var(--color--grey--medium);
    }
  }

  // Current

  .current a {
    display: block;
    font-size: 16px;
    font-weight: 700;
    color: var(--color--primary);

    > span {
      display: inline-block;
      font-size: 17px;
      margin-left: 16px;
      color: #000;
      vertical-align: middle;
    }
  }
}
