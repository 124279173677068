/* ==========================================================================
   #HEADINGS
   ========================================================================== */

/**
 * Simple default styles for headings 1 through 6. Anything more opinionated
 * than simple font-size changes should likely be applied via classes (see:
 * http://csswizardry.com/2016/02/managing-typography-on-large-apps/).
 */

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
   font-weight: 400;
   font-family: $font-headings;
   color: $color-dark-grey;
 }

h1,
.h1 {
  font-size: 42px;
}

h2,
.h2 {
  font-size: 32px;
  line-height: 42px;
}

h3,
.h3 {
  font-size: 26px;
  font-weight: 500;
}

h4,
.h4 {
  @include t-heading--h4;
}

h5,
.h5 {
  @include t-heading--h5;
}

h6,
.h6 {
  font-size: 1rem;
}

h1, h2, h3, h4, h5, h6 {

  em {
    color: $color-secondary-dark;
    font-style: normal;
  }
}

// Lined

.c-title-lined {
  display: block;
  width: 100%;

  h2, h3, h4 {
    display: inline-block;
    border-bottom: 3px solid $color-secondary;
    font-weight: normal;
    font-size: 1.3rem;
    padding-bottom: 0.3rem;
  }
}
