/* ==========================================================================
Components
  > Search
========================================================================== */


// Mobile

.c-search--mobile {
  width: 100%;
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 4;
  overflow: hidden;
  padding: var(--gutter);
  display: none;
  transition: opacity 0.2s ease-in-out;

  @include bp-large {
    display: none !important;
  }

  .c-btn--close__search {
    border-bottom-left-radius: var(--global--radius);
  }

  .c-site-search {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - var(--gutter) *2);
    max-width: 480px;
  }

  .close-button--search {
    position: absolute;
    right: 0;
    top: 0;
  }

  button.c-search-btn {
    position: absolute;
    right: 0;
    bottom: 25px;
    background: none;
    color: #222;
    border: 0;
    font-size: 1.5rem;
    cursor: pointer;

    span {
      color: #222;
    }
  }

}

// Predictive Search
.ui-menu {
  z-index: 999;
  background: #fff;
  list-style-type: none;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0,0,0,0.2);
  width: 300px;
}

.ui-menu li {
  margin-bottom: 0.5rem;
  cursor: pointer;
}

.ui-menu li:hover {
  text-decoration: underline;
  color: $color-primary;
}
