/* ==========================================================================
   #CONTACT PAGE
   ========================================================================== */

.c-contact-pod {
  background: #fff;
  padding: 3rem;
  width: 100%;

  @include bp-small {
    width: auto;
    float: left;
  }

  ul {
    margin: 0;
    padding: 0;

    @include bp-medium {
      margin: 0;
      padding: 0;
      border-bottom: 0;
    }

    li {
      list-style-type: none;
      margin-bottom: 5px;
      color: $color-secondary-dark;
    
      a {
        color: $color-secondary-dark;

        &:hover {
          color: $color-secondary;
          text-decoration: none;
        }
      }

      &.contact {
          margin-top: 0.5rem;

          span {
          width: 2rem;
          display: inline-block;

          &::before {
            display: block;
            transform: translateY(2px);
          }
        }
      }

      &.address {
        margin-bottom: 1.5rem;
      }
    }
  }
}
