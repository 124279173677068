/* ==========================================================================
Components
  > Dev
========================================================================== */

#__bs_notify__ {
  @include shadow(1);
  top: auto !important;
  bottom: var(--gutter) !important;
  left: var(--gutter) !important;
  right: auto !important;
  color: #999 !important;
  background: #fff !important;
  border-radius: 40px !important;
  height: 40px !important;
  line-height: 40px !important;
  margin-left: -1px;
  padding: 0 8px !important;
  text-align: center;
  font-size: 14px !important;
  z-index: 1001;
}
