/* ==========================================================================
   #MINICARD
   ========================================================================== */

.c-mini-card {
  background-color: $color-white;
  min-height: 100px;
  width: 100%;
  padding: 2rem;
  box-shadow: 0 10px 27px rgba(0,0,0,0.1);
  margin-bottom: 0.6rem;

  p {
    margin-bottom: 1rem;
  }

  h4 {
    margin-bottom: 1rem;
  }

  &.large-title h4 {
    font-size: 1.5rem;
    color: $color-secondary;
  }
}
