/* ==========================================================================
   #Articles
   ========================================================================== */

.c-article {
  position: relative;
  margin-bottom: var(--gutter);
}

// Standard List

.c-article-item {
  margin-bottom: 40px;
  clear: both;
  position: relative;

  @include bp-small {
    min-height: 200px;

    &.no-image {
      padding-right: 0px;
      min-height: 0px;
    }
  }

  @include bp-small {
    .c-article-item__content {
      overflow: auto;
    }
  }

  &.c-article--suggested {
    border: none;
    padding-bottom: 0;

    .c-article__header {
      display: inline-block;
      font-size: 14px;
      font-weight: 500;
      text-transform: uppercase;
      background: #EAEFF6;
      padding: 4px 16px;
    }

    .c-article-item__content {
      border: 2px solid #EAEFF6;
      padding: 24px;

      p {
        margin-bottom: 0;
      }
    }
  }

  .c-article-item__image {
    display: block;

    @include bp-small {
      float: left;
      width: var(--thumb--w);
      height: var(--thumb--h);
      margin-right: var(--gutter);

      // RHS e.g. News Listing

      &.c-img--right--sm {
        float: right;
        margin-right: 0;
        margin-left: var(--gutter);
      }
    }

    .c-thumb {
      max-height: 300px;

      @include bp-small {
        max-height: auto;
      }
    }

      .c-thumb a::after {
        display: none;
      }
  }

  .c-article-item__content {

    .meta {
      .category {
        display: inline-block;
        margin: 0;

        span {
          margin-left: 0.5rem;
          padding-right: 0.5rem;
          font-size: 16px;
          border-right: 1px solid #ccc;

          &:last-of-type {
            border-right: 0;
          }
        }
      }
    }

    .c-btn {
      margin-bottom: 2rem;
    }

    .author, .meta {
      font-size: 0.875rem;

      span {
        color: var(--color--primary);
        font-weight: 500;
        margin-right: 1.5rem;
      }
    }

    .author {
      margin-bottom: 2px;
    }

    .meta {
      margin-bottom: 18px;
    }

    .h3 {
      font-size: 21px;
      line-height: 26px;
      margin-bottom: 8px;

      a {
        color: #222;
        text-decoration: none;

        &:hover {
          @include t-link--hover;
        }
      }
    }
  }
}

  .single-meta {
    margin-bottom: 2rem;

    .date {
      font-size: 14px;
      font-weight: bold;
      margin-bottom: 0;
      background: $color-primary;
      display: inline-block;
      color: #fff;
      padding: 0.1rem 0.5rem;
    }

    .category {
      display: inline-block;
      margin: 0;

      span {
        margin-left: 0.5rem;
        padding-right: 0.5rem;
        font-size: 16px;
        border-right: 1px solid #ccc;

        &:last-of-type {
          border-right: 0;
        }
      }
    }
  }

  // Meta - Cats

  .c-meta__cats {
    color: var(--color--primary);
    font-weight: 500;
  }

  // Meta - Cats (below date @max-s)

@include bp-max-small {
  .c-meta__cats {
    clear: left;
    position: relative;
    top: 2px;
  }
}

// Meta - Cats (below date @l if in aside)

@include bp-large {
  aside {
    .c-meta__cats {
      clear: left;
      position: relative;
      top: 2px;
    }
  }
}

  // Meta - Date

  .c-meta__date {
    font-size: 14px;
    font-weight: 500;
    margin-right: 12px !important;
    margin-bottom: 0;
    background: $color-primary;
    color: #fff;
    padding: 0.1rem 0.5rem;

    &:not(.u-fl--l) {
      display: inline-block;
    }
  }

  // Author Pod

  .c-author-pod {
    width: 100%;
    position: relative;
    margin-top: 3rem;
    margin-bottom: 0;
    @include clearfix();

    h4 {
      margin-bottom: 0.5rem;
    }

    .thumb {
      width: 100px;
      height: 100px;
      margin-bottom: var(--gutter);

      img {
        width: 100%;
        height: auto;
        border-radius: 50px;
      }
    }

    @include bp-small {
      padding-left: calc(100px + var(--gutter));

      .thumb {
        position: absolute;
        left: 0;
        top: 0.5rem;
      }
    }
  }
  // List Small
  .c-article-small {
    margin-bottom: 1.5rem;

    .title {
      font-size: 1.2rem;
      margin-bottom: 0.5rem;
      line-height: 1.6rem;

      a {
        color: $color-secondary-dark;
      }
    }
  }

  .has-calendar {
    padding-left: 90px;
    position: relative;
  }

    .has-calendar .calendar {
      position: absolute;
      top: 0.4rem;
      left: 0;
      width: 60px;
      height: 60px;
      border: 1px solid #ccc;
      border-radius: $global-radius;
      text-align: center;
      padding: 5px;
      box-shadow: 0 3px 7px rgba(0,0,0,0.1);
    }

      .has-calendar .calendar .month {
        font-size: 16px;
        display: block;
        margin: auto;
        font-weight: bold;
        color: red;
        margin-top: -5px;
      }
  // Meta
  .c-meta {
    display: block;
    font-size: 14px;
    margin-bottom: 1rem;
    color: #888;

    span {
      margin-right: 0.8rem;
      margin-bottom: 0.5rem;
      display: inline-block;
    }

    .tag {
      background: $color-light-blue-grey;
      padding: 0.2rem 0.5rem;
      color: $color-secondary-dark;
    }

    .status {
      color: $color-secondary;
    }

      .status.red {
        color: $color-red;
      }

      .status.green {
        color: $color-green;
      }
  }
  // Events
  .c-event-list .col:last-child {
    margin-bottom: 0;
  }

.c-event {
  width: 100%;
  display: block;
  position: relative;

  @include bp-small {
    padding-left: 94px;

    .c-meta__date {
      display: none;
    }
  }

  .c-event--date {

    .month-year,
    .day {
      display: none;
    }

    @include bp-small {
      display: block;
      width: 70px;
      height: 70px;
      position: absolute;
      left: 0;
      top: 0;

      .month-year {
        display: block;
        height: 24px;
        line-height: 24px;
        width: 70px;
        background: $color-secondary;
        color: #fff;
        font-size: 13px;
        width: 100%;
        text-align: center;
        font-weight: 700;
        text-transform: uppercase;

        &.c-date--F {
          font-size: 12px;
        }
      }

      .day {
        display: block;
        width: 100%;
        height: 46px;
        line-height: 46px;
        text-align: center;
        font-weight: 500;
        font-size: 25px;
        border: 1px solid #ccc;
        border-top: 0;
      }
    }
  }
}

  // Filters

  .c-filters {
    @include clearfix();
    margin-top: 32px;
    margin-bottom: 8px;

    .c-filter__input-hold {
      position: relative;

      input {
        border-color: #979797;
        padding-right: calc(var(--tap-target--size) + 8px);
      }

      button {
        @include t-reset--btn;
        position: absolute;
        top: 50%;
        right: 1px;
        padding-right: 8px;
        width: var(--tap-target--size);
        height: var(--tap-target--size);
        transform: translateY(-50%);

        span {
          font-size: 13px;
        }
      }
    }

    .c-checkbox {
      padding-top: 8px;
    }
  }

  // Divided e.g. Search Result / Recent Articles

  .c-article--divided:not(:last-child) {
    margin-bottom: var(--gutter);
    padding-bottom: var(--gutter);
    border-bottom: 1px solid var(--color--grey--light);

    p {
      margin-bottom: 0;
    }
  }

  .c-article--divided:last-child {
    margin-bottom: 16px;
  }

  // Grid

  .c-article--grid {
    margin-bottom: 0;

    @include bp-small-medium {
      margin-bottom: 24px;
    }

    img {
      margin-bottom: 16px;
    }

    .h5 {
      margin-bottom: 8px;

      a {
        text-decoration: none;
      }
    }

    p {
      margin-bottom: 0;
    }
  }

// Header

main article > header {
  margin-top: 8px;
  margin-bottom: 48px;

  // Restricted

  @include bp-medium-large {
    &.c-article__header--restricted {
      width: 75%;
    }
  }

  @include bp-large {
    margin-top: 0;
  }
}

// Footer

main article > footer {

  // Restricted

  @include bp-medium-large {
    &.c-article__footer--restricted {
      width: 75%;
    }
  }
}