/* ==========================================================================
   #NAVIGATION
   ========================================================================== */

.c-main-nav--wrapper {
  display: none;

  @include bp-large {
    display: block !important;
    background: none;
    border: 0;
    height: 56px;
    border-top: 1px solid var(--color--grey);
  }

  .o-container {
    position: unset;
  }
}

.c-main-nav {

  .o-container {
    padding: 0;

    @include bp-large {
      padding: 0 25px;
    }
  }

  > ul {
    margin: 0;
    padding: 0;
  }
}


.c-main-nav > ul > li {
  display: inline-block;
  padding: 0 16px;
  height: 55px;

  &:first-child {
    padding-left: 0;
  }

  .c-ada-mega-menu--btn {
    background: none;
    border: 0;
    cursor: pointer;
    color: $color-text;
    height: 55px;
    position: relative;

    &:focus {
      outline: 2px dotted  $color-primary;
    }
  }
}

.c-main-nav > ul > li > a {
  text-decoration: none;
  display: inline-block;
  font-size: 19px;
  height: 55px;
  line-height: 53px;
  color: #444;
  position: relative;
  font-weight: 400;

  &:hover {
    text-decoration: none !important;
  }
}

.c-main-nav > ul > li > button {
  background: none;
  border: 0;
  font-size: 12px;
  cursor: pointer;

  &:focus {
    outline: 2px dotted $color-primary;
  }

  &:hover {
    color: $color-primary;
  }
}


.c-main-nav > ul > li.active > a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 4px;
  background: $color-primary;
}

.moon-hamburger {
  color: #222;
}

// Mega Menu

.c-mega-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  background: #fff;
  top: var(--mega-menu__position--top);
  left: 0;
  height: auto;
  bottom: auto;
  z-index: 9;
  box-shadow: 0 10px 15px rgba(0,0,0,0.15);
  border-top: 1px solid var(--color--grey);

  .sidenav li > button {
    background: none;
    border: 0;
    width: 100%;
    font-weight: 500;
    padding: 1rem 1.5rem;
    text-align: left;
    position: relative;
  }

  .sidenav li > button:focus, .sidenav li > button:hover {
    background: #f5f5f5;
    outline: 0;
    cursor: pointer;
  }

  .sidenav li.active > button {
    background: #EAEFF6 !important;
    outline: 0;
  }

  .o-container {
    position: relative;
  }
}

// Has Alert

.has--alert {
  .c-mega-menu {
    top: calc( var(--mega-menu__position--top) + var(--alert--h--desktop));
  }
}

.c-main-nav > ul > li.active .c-mega-menu {
  opacity: 1;
  visibility: visible;
}

.c-mega-menu > .o-container > ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  width: 320px;
  border-right: 1px solid $color-light-grey;
  padding: 2rem 0;
  min-height: 400px;
}

.c-mega-menu > .o-container > ul > li {

  > a {
    display: block;
    padding: 0 3rem 0 1rem;
    font-size: 16px;
    font-weight: 400;
    height: 38px;
    line-height: 36px;
    position: relative;
  }

  &.view-all {
    margin-top: 1rem;

    a {
      background: none !important;
      color: $color-primary !important;

      &::after {
        display: none;
      }

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &.active {

    > a {
      background: $color-very-light-grey;

      &::after {
        content: "\ede3";
        font-family: "icomoon";
        position: absolute;
        right: 1rem;
        top: 1px;
        font-size: 14px;
      }
    }

    .c-mega-menu--main {
      visibility: visible;
      z-index: 2;
    }
  }

  a {
    color: #222;
    text-decoration: none;

    &:hover {
      color: $color-primary;
    }
  }
}

.c-mega-menu--main {
  visibility: hidden;
  position: absolute;
  left: 344px;
  right: 0;
  padding-left: 50px;
  top:2rem;
  min-height: 400px;
  z-index: -1;
}

.c-mega-menu--main .title {
  font-size: 22px;
  font-weight: 500;
}

.c-mega-menu--main ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
  @include clearfix();

}

.c-mega-menu--main ul li {
  margin-bottom: 0.5rem;

  a {
    font-size: 16px;
    color: #666!important;

    &:hover {
      color: $color-primary!important;
      text-decoration: underline!important;
    }
  }
}

.c-mega-menu--main a.view-all {
  color: $color-secondary!important;
  margin-top: 2rem;
  float: left;
  font-size: 16px;

  &:hover {
    text-decoration: underline!important;
  }
}

.c-mega-menu--innerlinks {
  float: left;
  width:  50%;

  .title {
    color: $color-primary;
  }
}

.c-mega-menu--feature {
  margin-left: 10%;
  float: left;
  width: 30%;
}

.c-mega-menu--feature .title {
  font-size: 1rem;
  margin-top: 1rem;
  margin-bottom: 0.5rem;
  line-height: 1.2rem;
  color: $color-primary;
}

.c-mega-menu--feature .meta {
  font-size: 14px;
  text-transform: uppercase;
  color: #888;
}

.sidenav::after {
  content: "";
  display: block;
  position: absolute;
  right: 0;
  top: 0;
  left: 345px;
  bottom: 0;
  width: 50px;
  background: rgb(249,249,249);
  background: linear-gradient(90deg, rgba(249,249,249,1) 0%, rgba(249,249,249,0) 48%);
  z-index: 1;
}

.has-strip-banner .c-mega-menu {
  top: 226px;
  }

  // Drop Menu
  .drop-menu {
    position: relative;
  }

  .drop-menu > ul {
    @include shadow(3);
    visibility: hidden;
    opacity: 0;
    position: absolute;
    left: 16px;
    margin: 0;
    top: 55px;
    background: #fff;
    padding: 8px 0 12px 0;
    border-top: 0;
    z-index: 20;
    width: 275px;
    list-style-type: none;
    border-radius: 0 0 var(--global--radius) var(--global--radius);

    li {
      a {
        display: block;
        color: var(--color--primary);
        text-decoration: none;
        font-size: 16px;
        padding: 8px 16px;

        &:hover {
          @include t-link__hover;
          background: #f5f5f5;

        }
      }
    }
  }

  .drop-menu.active > ul {
    visibility: visible;
    opacity: 1;
  }


// ADA Mega Menu

.c-ada-mega-menu {
  opacity: 0;
  visibility: hidden;
  position: absolute;
  width: 100%;
  background: #fff;
  top: 176px;
  left: 0;
  height: auto;
  bottom: auto;
  z-index: 9;
  box-shadow: 0 10px 15px rgba(0,0,0,0.15);

  &.active {
    opacity: 1;
    visibility: visible;
  }

  .o-container {
    padding: 3.5rem 25px;
    position: relative;

    .c-ada-mega-menu--close {
      position: absolute;
      right: 2rem;
      top: 2rem;
      font-size: 1.2rem;
      background: none;
      border: 0;
      cursor: pointer;

      &:focus, &:hover {
        outline: 2px dotted $color-primary;
      }
    }

    .c-ada-mega-menu--intro {
      float: left;
      width: 40%;
      padding-right: 10rem;
    }

    .c-ada-mega-menu--links {
      float: left;
      width: 60%;

      ul {
        list-style-type: none;
        margin: 0;
        column-count: 3;

        li {
          margin-bottom: 0.5rem;
        }
      }
    }

    .row {
      float: left;
      width: 60%;
      margin-bottom: 0;

      .col {

        h3 {
          font-size: 20px;
        }
      }

      ul {
        list-style-type: none;
        margin: 0;
      }
    }
  }
}
