/* ==========================================================================
   #COOKIES
   ========================================================================== */

.c-cookie {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 999;
  background: #fff;
  width: 100%;
  box-shadow: 0 -3px 12px rgba(0,0,0,0.24);
  padding: var(--gutter) 0;

  .row,
  .col {
     margin-bottom: 0;
  }

  .col {
    display: flex;
    flex-direction: column;

    @include bp-medium {
      flex-direction: row;
      align-items: center;
    }
  }

  p {
    font-size: 16px;

    @include bp-medium {
      width: 70%;
      margin: 0;
      padding-right: var(--gutter);
    }
  }

  @include bp-medium {
    button {
      margin-left: auto;
      width: 30%;
      max-width: 300px;
    }
  }
}