
/* ==========================================================================
Utilities
  > Gradients
========================================================================== */

.u-gradient--fw {
  position: relative;
  padding-top: 70px;
  height: 70px;
  background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.05) 64%);

  &:before,
  &:after {
    content: '';
    background-image: linear-gradient(-180deg, rgba(0,0,0,0.00) 0%, rgba(0,0,0,0.05) 64%);
    position: absolute;
    width: 10000px;
    height: 70px;
    top: 0;
  }

  &:before {
    left: 0;
    margin-left: -10000px;
  }

  &:after {
    right: 0;
    margin-right: -10000px;
  }
}
