/* ==========================================================================
   #NAVIGATION
   ========================================================================== */

.c-sub-nav > ul {
  background: #eee;
  border-top: 1px solid #ddd;

  li {
    position: relative;
  }
}

/* Reset list */

.c-sub-nav > ul,
.c-sub-nav > ul ul {
  list-style-type: none;
}

.c-sub-nav > ul {
  margin: 0 0 32px 0;
}

.c-sub-nav > ul ul {
  margin: 0;
}

/* Hide child lists, apply bg */

.c-sub-nav >ul > li > ul {
  display: none;

  li {
    background: #fff;
  }
}

/* Show child list when parent active */

.c-sub-nav > ul > .Highlighted > ul {
  display: block;
}

/* a */

.c-sub-nav > ul a {
  padding: 12px 25px;
  display: block;
  color: #444;
  text-decoration: none;
  border-bottom: 1px solid #ddd;
}
