/* ==========================================================================
Tools
  > Cards
========================================================================== */

// Horizontal

@mixin t-card--horizontal--l {
  flex-direction: row;
  padding: 0;

  // Media

  &.c-card--image .c-card__media {
    position: relative;
    margin: 0;
    width: 34.3450479233227%;
    min-width: 215px;
    border-radius: var(--global--radius) 0 0 var(--global--radius);
  }

  // Media - Product

  &.c-card--product {

    .c-card__media {
      width: 112px;
      margin: 0;
      padding: 32px;

      &.u-b {
        border-bottom: none;
        border-right: 1px solid rgba(144,157,185,0.33);
      }
    }
  }

  // Body

  .c-card__body {
    padding: 32px 40px 0 40px;
  }

  // Action

  .c-card__action,
  .c-card__sub-text {
    padding: 0 40px 40px 40px;
  }
}
