/* ==========================================================================
   #Info Pod
   ========================================================================== */


.c-info-pod {
  margin-top: 4rem;

  @include bp-large {
    margin-top: 6rem;
  }

  @include clearfix();

  .c-info-pod--img {
    position: relative;
    width: 100%;
    z-index: 1;
    margin-bottom: 1.5rem;
    max-height: 24rem;
    overflow: hidden;

    @include bp-large {
      max-height: none;
    }

    img {
      width: 100%;
      height: auto;
      transform: scale(1.01);
      transition: 0.5s all ease-in-out;
    }

    @include bp-medium {
      width: 40%;
      float: left;
      margin-bottom: 0rem;
    }

    @include bp-large {
      width: 35%;

      &:hover {

        img {
          transform: scale(1.05);
        }
      }
    }
  }

  .c-info-pod--content {
    position: relative;
    background: #fff;
    padding: 0rem;
    z-index: 2;
    overflow: hidden;
    width: 100%;

    @include bp-medium {
      width: auto;
    }

    h2 {

      a {
        transition: 0.3s all ease-in-out;
        color: $color-secondary;

        &:hover {
          color: $color-primary;
          text-decoration: none;
        }
      }
    }

    &::before {
      content: "";
      display: block;
      width: 6px;
      height: 100%;
      background: $color-primary;
      z-index: 1;
      position: absolute;
      top: 50%;
      left: 0;
      opacity: 0;
      transition: all 0.3s ease-in-out;
      transition-delay: 0.6s;
    }

    &.red::before {
      background: $color-red;
    }

    &.aos-animate {

      @include bp-large {
        box-shadow: 0px 5px 20px 0 rgba(0,0,0,0.1);
      }

      &::before {
        top: 0;

        @include bp-large {
          opacity: 1;
        }
      }
    }

    @include bp-medium {
      width: 55%;
      float: right;
    }

    @include bp-large {
      float: left;
      width: 72%;
      padding: 3rem;
      margin-top: 4rem;
      margin-left: -7%;
    }

    @include bp-largest {
      margin-left: -8rem;
    }

    .c-btn {
      margin-top: 2rem;
    }
  }

  &.right {

    .c-info-pod--img {
      float: right;
    }

    .c-info-pod--content {
      float: left;
      margin-left: 0;
      margin-right: -7%;
    }
  }
}
