
/* ==========================================================================
Utilities
  > Backgrounds
========================================================================== */

/// <reference path="../settings/_global.scss" />
/// <reference path="../tools/_breakpoints.scss" />

// Grey

.u-background-grey {
  background: $color-very-light-grey;
}

.u-background-blue-grey {
  background: $color-medium-blue-grey;
}

// Blue

.u-background-blue {
  background: $color-primary;
  background-repeat: no-repeat;
  background-position: bottom right;
  color: #fff;

  h2, h3 {

    span {
      background: #004978;
    }
  }

  @include bp-medium {
    background-size: 50%;
  }
}

.u-background-blue--plain {
  background: $color-secondary;
}

.u-background-dark-blue {
  background: $color-tertiary;
}

// Light Blue

.u-background-light-blue {
  background: $color-light-blue;
}


// Green

.u-background-green {
  background: $color-green;
}

// Purple

.u-background-purple {
  background: $color-purple;
}

// Red

.u-background-red {
  background: $color-red;
}

// Small Angle BG

.u-background-angle--small {
  background: $color-very-light-grey;

  @include bp-large {
    background: $color-very-light-grey url('../assets/images/gfx/angle-bg-small--green.svg');
    background-position: center center;
    background-repeat: no-repeat;

    &.purple {background: $color-very-light-grey url('../assets/images/gfx/angle-bg-small--purple.svg'); background-position: center center; background-repeat: no-repeat;}
    &.red {background: $color-very-light-grey url('../assets/images/gfx/angle-bg-small--red.svg'); background-position: center center; background-repeat: no-repeat;}
    &.blue {background: $color-very-light-grey url('../assets/images/gfx/angle-bg-small--blue.svg'); background-position: center center; background-repeat: no-repeat;}
  }

  @include bp-full {
    background-size: cover;
  }
}

// Large Angle BG

.u-background-angle--large {
  background: $color-purple;

  &.purple {background: $color-purple;}
  &.red {background: $color-primary;}
  &.blue {background: $color-secondary-dark;}

  @include bp-large {
    background: $color-very-light-grey url('../assets/images/gfx/angle-bg-large--green.svg');
    background-position: center center;
    background-repeat: no-repeat;

    &.purple {background: $color-very-light-grey url('../assets/images/gfx/angle-bg-large--purple.svg'); background-position: center center; background-repeat: no-repeat;}
    &.red {background: $color-very-light-grey url('../assets/images/gfx/angle-bg-large--red.svg'); background-position: center center; background-repeat: no-repeat;}
    &.blue {background: $color-very-light-grey url('../assets/images/gfx/angle-bg-large--blue.svg'); background-position: center center; background-repeat: no-repeat;}
  }

  @include bp-full {
    background-size: cover;
  }

}

// Neon Blue

.u-background-neon {
  background: #00ABCA;
  background-repeat: no-repeat;
  background-position: bottom right;

  @include bp-medium {
    background-size: 50%;
  }
}

.u-background-neon--plain {
  background: #00ABCA;
}

// Overlay Backgrounds

.u-overlay-purple, .u-overlay-blue {
  background-size: cover;
}

.u-overlay-purple::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(175,10,95,0.85);
  }
  
.u-overlay-blue::after {
  content: "";
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0,91,150,0.90);
  }

.u-bg--white {
  background-color: #fff;
}

.u-bg--blue--gradient--tr {
  background: linear-gradient(to right, #3868b4 0%, #214375 100%);
}

.has--bg--img {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
}
