/* ==========================================================================
   Tools
     > Form -- UI
   ========================================================================== */

@mixin t-form--ui {
  background: none;
  border: 1px solid var(--color--grey--dark);
  padding: 0 16px;
  width: 100%;
  height: 40px;
  line-height: 38px;
  border-radius: 40px;
  font-family: $font-body;
  font-weight: 400;
  color: $color-text;

  &:focus {
    @include shadow(1);
    outline: none;
    border-color: var(--color--primary);
  }
}

/*
  Floated Labels:
    https://github.com/tonystar/float-label-css
    https://github.com/tonystar/float-label-css/blob/master/scss/_mixins.scss
*/

@mixin float-label-container {
  display: block;
  position: relative;
}

@mixin float-label {
  label {
    pointer-events: none;
    position: absolute;
    top:-22px;
    left: 0;
    font-size: 13px;
    color: #666;
    cursor: text;
    transition: all 0.2s;
    @content;
  }
}

@mixin float-label-input {
  &::placeholder {
    opacity: 0;
    transition: all 0.2s;
  }

  &:placeholder-shown:not(:focus)::placeholder {
    opacity: 0;
  }
}

@mixin float-label-scaled {
  &:placeholder-shown:not(:focus) + * {
    @content;
  }
}
