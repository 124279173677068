/* ==========================================================================
   Tools
     > Form -- UI -- Alt
   ========================================================================== */

@mixin t-form--ui--alt {
 
  .c-form__control {
    margin: 0 0 32px 0;
  }

  input,
  select,
  textarea {
    border-radius: var(--radius--c-form);
  }

  .c-dropdown {
    display: inline-block;
    width:auto;

    select {
      width: auto;
      min-width: 185px;
    }
  }

  .c-checkbox,
  .c-radio {
    margin-bottom: 8px;
  }

  .c-site-search.c-search--secondary button {
    border-radius: var(--radius--c-form);
  }
}
