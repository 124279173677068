/* ==========================================================================
   #Figures
   ========================================================================== */

.c-figure {
  overflow: hidden;
  display: block;
  background-color: #fff;

  // Ratios

  @include fluid-aspect(4 3);

  &.c-figure-ratio--1-1 {
    @include fluid-aspect(1 1);
  }

  &.c-figure-ratio--3-4 {
    @include fluid-aspect(3 4);
  }

  &.c-figure-ratio--16-9 {
    @include fluid-aspect(16 9);
  }

  &.c-figure-ratio--16-10 {
    @include fluid-aspect(16 10);
  }

  &.c-figure-ratio--9-16 {
    @include fluid-aspect(9 16);
  }

  &.c-figure-ratio--13-6 {
    @include fluid-aspect(13 6);
  }

  &.c-figure-ratio--11-4 {
    @include fluid-aspect(11 4);
  }

  &.c-figure-ratio--31-10 {
    @include fluid-aspect(31 10);
  }
}

