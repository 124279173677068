/* ==========================================================================
   #LINKS
   ========================================================================== */

// Shared

.c-link__result {
  text-decoration: none;
}

// Result cat

.c-link__result--cat {
  background: #fff;
  font-size: 12px;
  text-transform: uppercase;
  padding: 4px 8px;
  border-radius: $global-radius;
  text-align: center;
  float: right;
}

// Email

.c-email a {
  text-decoration: none;
  font-weight: 500;
  font-size: 14px;
  color: #111;
}

// Icons

.c-link__icon {
  background-position: center left;
  background-repeat: no-repeat;
  background-size: 24px auto;
  padding: 8px 0 8px 32px;
  text-decoration: none;
  position: relative;
  display: inline-block;

  .c-link__copy {
    display: inline-block;
    border-bottom: 1px solid #fff;
  }

  // Light

  &.c-link__icon--light {
    color: #fff;
  }

  // Dark

  &.c-link__icon--dark {
    color: $color-link;
  }

  // Bgs

  &.c-link__icon--xls {
    background-image: url('../assets/images/icon-xls.svg');
  }
}

.c-file__upload {
  position: relative;
}

// Cover

.c-link--cover {
  color: #444;
  text-decoration: none;

  &:hover {
    @include t-link--hover;

    + .h5 {
      text-decoration: underline;
    }
  }

  &:after {
    content: "";
    position: absolute;
    inset: 0px;
    z-index: 2;
  }
}

// Featured Topics

.c-link--featured-topic {
  position: relative;
  border: 2px solid #EAEFF6;
  padding: 32px;

  p {
    margin: 0;
  }
}