/* ==========================================================================
Settings
  > Global
========================================================================== */

@import 'tools/_breakpoints';

:root {

  // Helpers

  --gutter:                        28px;
  --gutter--alt:                   36px;
  --header--height:                50px;
  --global--radius:                8px;
  --radius--c-form:                5px;
  --padding--wrapper:              40px;
  --padding--wrapper--minor:       16px;
  --padding--wrapper--major:       64px 0 56px;
  --padding--wrapper--quick-link:  48px 0;
  --spacer:                        24px;
  --tap-target--size:              40px;
  --thumb--w:                      177px;
  --thumb--h:                      177px;
  --padding--btn:                  0 30px;
  --padding--btn--hero:            0 36px;
  --alert--h--desktop:             48px;
  --mega-menu__position--top:      205px;

  // Colour (Text)

  --color--text:                $color-text;

  // Colours (Primary)

  --color--primary:                #2E5797;
  --color--primary--blue--light:   #0B85D0;
  --color--primary--apa:           #003399;
  --color--primary--navy:          #1E376D;
  --color--primary--charcoal:      #343434;

  // Colours (Secondary)

  --color--secondary:              #E05929;
  --color--secondary--teal:        #00667f;
  --color--secondary--teal--light: #09839B;
  --color--secondary--green:       #245e38;
  --color--secondary--wine:        #692e44;

  // Colours (Tertiary)

  --color--tertiary--green:        #70967D;
  --color--tertiary--wine:         #9D7785;
  --color--tertiary--blue:         #7791BB;
  --color--tertiary--orange:       #E98A68;
  --color--tertiary--grey:         #EBEBEB;

  // Colours (Greys)
  
  --color--grey:                   #CCC;
  --color--grey--medium:           #C8C8C8;
  --color--grey--dark:             #979797;
  --color--grey--light:            #DEDEDE;

  // Colours (Misc)

  --color--blue--light:            #F1F4F9;
}

// Brand Colours
$color-primary: #2E5797;
$color-primary-dark: darken( $color-primary, 10% );
$color-secondary: #E05929;
$color-secondary-dark: darken( $color-secondary, 10% );
$color-tertiary: #1E376D;

// Secondary Colors
$color-white: #fff;
$color-green: #1B998B;
$color-purple: #61234E;
$color-red: #ce1d2b;
$color-blue: #0B85D0;
$color-light-blue: #EAEFF6;

// Greys
$color-very-light-grey: #F6F6F6;
$color-light-blue-grey: #EFF0F5;
$color-medium-blue-grey: #DFE2E7;
$color-light-grey: #E5E8F1;
$color-light-medium-grey: #BFC3D1;
$color-medium-grey: #80828F;
$color-dark-grey: #343434;
$color-black: #000;

// Text
$color-bg: $color-white;
$color-text: #444;

// Links
$color-link: var(--color--primary--blue--light);
$color-hover: $color-primary;
$color-hover--alt: $color-primary;

// Borders
$color-border: $color-very-light-grey;

// Fills
$color-fill: $color-very-light-grey;

// Social
$color-facebook: #3B5998;
$color-twitter: #00a9f0;
$color-linkedin: #0077b5;
$color-googleplus: #DD4B39;


// Radius

$global-radius: 5px !default;

// Transition

$global-transition: all 180ms ease-in-out !default;

// Fonts

$font-body: 'Roboto', sans-serif;
$font-headings: 'Roboto', sans-serif;
$font-headings--light: 'Roboto', sans-serif;

// Width

$u-max-width-1080: 1080px;
$u-max-width-60p: 60%;
$u-max-width-60em: 60em;
$u-max-width-40p: 40%;
$u-max-width-380: 380px;

// Grid

$gutter: 2rem;

// Breakpoints

// Min

$bp-xsmall: 320px;
$bp-small: 480px;
$bp-small-medium: 540px;
$bp-small-medium-extended: 600px;
$bp-medium: 768px;
$bp-medium-large: 960px;
$bp-large: 1024px;
$bp-xlarge: 1280px;
$bp-full: 1600px;
$bp-largest: 1921px;

// Max

$bp-max-small: 479px;
$bp-max-small-medium: 539px;
$bp-max-medium: 767px;
$bp-max-medium-large: 959px;
$bp-max-large: 1023px;

@include bp-medium {
  :root {
    --padding--wrapper: 48px;
  }
}

@include bp-large {
  :root {
    --padding--wrapper: 56px;
  }
}
