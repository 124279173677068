/* ==========================================================================
   #STRIP BANNER
   ========================================================================== */

.c-strip-banner {
  width: 100%;
  min-height: 50px;
  background: #222;
  text-align: center;
  position: relative;
  display: none;


  .o-container {
    position: unset;
  }

  .large {
    display: none;
    padding: 5px 0 0 0;

    @include bp-large {
      display: block;
    }
  }

  .compact {
    padding: 1rem 0;

    @include bp-large {
      display: none;
    }
  }

  img {
    display: inline-block;
    line-height: 50px;
    margin-right: 1rem;
  }

  p {
    display: inline-block;
    color: #fff;
    font-size: 16px;
    margin-right: 1rem;
    margin-bottom: 0;
  }

  a {
    display: inline-block;
    color: $color-primary;
    font-size: 16px;
    font-weight: bold;
    position: relative;
    text-decoration: underline;
    margin-bottom: 0;

    &:hover {
      color: lighten( $color-primary, 5% );
    }
  }

  &.red {
    background: $color-red;

    a {
      color: white;
    }
  }

  &.blue {
    background: $color-blue;

    a {
      color: white;
    }
  }

  &.light-blue {
    background: $color-light-blue;

    a {
      color: white;
    }
  }

  &.green {
    background: $color-green;

    a {
      color: white;
    }
  }

  &.purple {
    background: $color-purple;

    a {
      color: white;
    }
  }

  .moon-x {
    position: absolute;
    right: 1rem;
    top: 12px;
    cursor: pointer;
    color: #999;
    font-size: 22px;
  }
}

.has-strip-banner .c-strip-banner {
    display: block;
}


.dcbar.has-strip-banner .c-hero-banner {
  padding: 9rem 0 2rem 0;

  @include bp-small {
    padding: 10rem 0 6rem 0;
  }

  @include bp-large {
    padding: 20rem 0 16rem 0;
  }
}
