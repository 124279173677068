/* ==========================================================================
   #WRAPPER
   ========================================================================== */

/**
 * Page-level constraining and wrapping elements.
 */

.o-wrapper {
  @include clearfix;
  margin-right: auto;
  margin-left: auto;
  padding: var(--padding--wrapper) 0;
  width: 100%;
}

// Minor

.o-wrapper--minor {
  padding: var(--padding--wrapper--minor) 0;
}

@include bp-medium {
  .o-wrapper--minor--m {
    padding: var(--padding--wrapper--minor) 0;
  }
}

// Major (@max sm)

@include bp-max-small-medium {
  .o-wrapper--major--max--sm {
    padding: var(--padding--wrapper--major);
  }
}

// Quick link

.o-wrapper--quick-link {
  padding: var(--padding--wrapper--quick-link);
}

// Hero

.o-wrapper--hero {
  background-position: center center;
  background-size: cover;
  background-repeat: no-repeat;
}
