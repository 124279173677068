/* ==========================================================================
Components
  > Modals
========================================================================== */

// Hold

.c-modal {
  display: none;
  opacity: 0;
  transition: opacity 0.15s linear;
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 10;
  overflow-x: hidden;
  overflow-y: auto;

  &.show-modal {
    display: block;
    opacity: 1;

    .c-modal__dialog {
      transform: translate(0,0);
    }
  }

  // Bg

  &:before {
    content: '';
    background: rgba(0,0,0,0.64);
    position: fixed;
    width: 100%;
    height: 100%;
  }

  // Inline

  .c-modal__dialog {
   display: flex;
   align-items: center;
   min-height: calc(100% - (0.5rem * 2));
   transition: transform 0.3s ease-out;
   transform: translate(0,-25%);

    @include bp-xsmall {
      margin: 16px auto;
    }

     @include bp-medium {
      margin: var(--gutter) auto;
    }
  }

  // Content

  .c-modal__content {
    @include shadow(4);
    border-radius: var(--global--radius);
    display: flex;
    flex-direction: column;
    pointer-events: auto;
    position: relative;
    background: #FFF;
    width: auto;
    max-width: 576px;
    margin: 0 auto;
    padding: 24px;

    @include bp-small-medium {
      padding: 48px;
    }

    // Close

    .c-btn--close {
      border-top-right-radius: var(--global--radius);
      border-bottom-left-radius: var(--global--radius);
    }

    // Article

    article {
      max-width: calc(100% - var(--tap-target--size));
      margin-bottom: var(--spacer);

      &:last-child {
        margin-bottom: 0;
      }
    }

    // Heading

    @include bp-max-medium {
      .h4 {
        font-size: 18px;
      }
    }
  }
}


// Active - html

.modal--active {
  overflow: hidden;
  padding-right: 17px;
}

// Trigger

.c-btn--modal {
  @include t-reset--btn;

  &:hover {
    text-decoration: underline;
    color: var(--color--primary);
  }

  &:focus {
    outline: 2px dashed var(--color--primary);
  }
}