/* ==========================================================================
   #ACCORDION
   ========================================================================== */


// Hide

.c-accordion {
  opacity: 0;
  transition: $global-transition;
  margin-top: 3rem;
  margin-bottom: 3rem;
}

// Until loaded

.c-accordion.ui-accordion {
  opacity: 1;
  transition-delay: 0.25s;
}

// Set up as reusable component

.c-accordion,
.ui-accordion {

  // Add a custom block we can style

  .c-accordion__block {
    margin-bottom: 1.5rem;
    @include clearfix();
    background: #fff;
  }

  // Header

  .ui-accordion-header {
    position: relative;
    cursor: pointer;
    outline: none;
    padding: 0.7rem 4rem 0.7rem 1.5rem;
    margin: 0;
    font-size: 18px;
    font-weight: normal;
    background: $color-very-light-grey;

    // Arrow

    &::after {
      content: "\ede7";
      transition: $global-transition;
      font-family: 'icomoon';
      color: $color-primary;
      font-size: 1.1rem;
      position: absolute;
      top: 0.85rem;
      right: 1.5rem;
    }

    &.ui-accordion-header-active {
      color: #fff;
      background: $color-secondary;
    }

    &:hover::before {
      background: $color-secondary;
    }

    &.ui-accordion-header-active:after {
      transform: rotate(-180deg);
      color: #fff;
    }
  }

  &.blue .ui-accordion-header-active {
    background: $color-blue !important;
  }

  &.light-blue .ui-accordion-header-active {
    background: $color-light-blue !important;
  }

  &.green .ui-accordion-header-active {
    background: $color-green !important;
  }

  &.purple .ui-accordion-header-active {
    background: $color-purple !important;
  }

  &.red .ui-accordion-header-active {
    background: $color-red !important;
  }

  .ui-accordion-content {
    padding: 2rem 2rem 1rem 2rem;

    p:last-child {
      margin-bottom: 0;
    }
  }
}


// Accessible Accordion

.c-ada-accordion {
  margin-bottom: 1.5rem;
}

.c-ada-accordion--header {
  margin: 0;
  position: relative;
  background: $color-light-blue;

  &[class*=" u-background"] button {
    color: #fff !important;
  }
}

/* Spacing between headers */
.c-ada-accordion--panel + .c-ada-accordion--header {
  margin-top: 5px;
}

/* Actionable button filling entire heading */

.c-ada-accordion--header button {
  display: block;
  border: 0;
  background: none;
  width: 100%;
  text-align: left;
  padding: 12px 48px 12px 16px;
  cursor: pointer;
  box-shadow: none;
  color: $color-text;
  outline: none;
  margin: 0;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.4;
}

.c-ada-accordion--header button:focus {
  box-shadow: 0 0 0 2px var(--color--primary);
  outline: none;
}

/* Indicator icon on left side */

.c-ada-accordion--header .icon {
  display: block;
  position: absolute;
  right: 16px;
  top: 17px;
  font-size: 16px;
  color: $color-tertiary;
}

/* Flip icon automatically when panel is expanded */

.c-ada-accordion--header button[aria-expanded="true"] .icon {
  transform: rotateX(180deg);
}


/*
  Panels = content areas whose visibility is controlled by headers
*/

.c-ada-accordion--panel {
  margin: 0;
  display: none;
  padding: 16px 0 8px 0;
}
