/* ==========================================================================
   #SQUARE PODS
   ========================================================================== */

.c-square-pods {
  width: 100%;
  clear: both;
  overflow: hidden;
  margin-top: 0.3rem;
  padding-left: 0.3rem;
  padding-right: 0.3rem;

  .c-square-pod {
    width: 100%;
    position: relative;
    overflow: hidden;
    border: 0.3rem solid #fff;
    background: $color-secondary;

    iframe {
      max-height: 300px;
    }

    @include bp-medium {
      width: 50%;
      float: left;
      padding-bottom: 40%;

      iframe {
        max-height: none;
        position: absolute;
        top: 0;
        right: 0;
        left: 0;
        bottom: 0;
      }
    }

    @include bp-xlarge {
      width: 25%;
      padding-bottom: 25%;
      float: left;
      min-height: 400px;
    }

    .c-square-pod--content {
      padding: 2rem;

      @include bp-medium {
        padding: 2rem;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      @include bp-large {
        padding: 3rem 3rem 4rem 3rem;
      }

      @include bp-full {
        padding: 4rem 4rem 6rem 4rem;
      }


      .c-arrow {
        height: 50px;
        display: block;
        line-height: 50px;
        font-size: 1.4rem;
        transition: all 0.3s ease-in-out;
        color: #fff;

        @include bp-medium {
          position: absolute;
          bottom: 2rem;
          left: 2rem;
        }

        @include bp-large {
          bottom: 3rem;
          left: 3rem;
        }

        @include bp-full {
          bottom: 4rem;
          left: 4rem;
        }

        &::after {
          content: "\ede3";
          font-family: "icomoon";
          color: #fff;
          font-size: 18px;
          margin-left: 1rem;
        }

        &:hover {
          transform: translateX(10px);
        }
      }

      a {
        text-decoration: none;
      }

      h3 {
        color: #fff;
        font-size: 2.2rem;

        &:hover {
          text-decoration: underline;
        }
      }

      p {
        color: #fff;
        font-size: 1rem;
      }
    }

    .c-square-pod--img {
      background-size: cover;
      padding-bottom: 70%;

      @include bp-medium {
        padding-bottom: 0;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }

    &.two-col .c-square-pod {
      @include bp-large {
        width: 50%;
        padding-bottom: 30%;
        float: left;
      }
    }
}
