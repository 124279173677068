/* ==========================================================================
   #NAVIGATION MMENU
   ========================================================================== */

#menu:not(.mm-menu) {
  display: none;
}

.mm-menu.mm-offcanvas {
  max-width: 350px;
}

.mm-menu {
  background: #fff;
  font-family: $font-headings;
  font-weight: 500;
  text-transform: uppercase;

  .mm-listview li a {
    font-size: 1.125rem;
  }
}

em.mm-counter + a.mm-next {
  width: 50px;
}

em.mm-counter + a.mm-next + a, em.mm-counter + a.mm-next + span {
  margin-right: 50px;
}

// navbar

.mm-navbars-top {
  height: 40px;
}

.mm-navbar {

  .mm-title {
    font-size: 14px;
  }

  .moon-house {
    font-size: 20px;
    text-align: left;
    padding: 6px 0 0 20px;
    
    &::before {
     line-height: 38px;
     color: $color-secondary;
    }
  }

  .moon-x {
    font-size: 22px;
    text-align: right;
    padding: 0 15px 0 0;

    &::before {
      line-height: 38px;
      color: $color-primary;
    }
  }
}

.mm-navbars-bottom a {
  font-size: 18px;
}

.mm-navbars-top {
  height: 60px;
}

.mm-navbar {
  height: 50px;
}

.mm-navbar a {
  margin-top: 5px;
}

.mm-navbar a.moon-logo, .mm-navbar a.moon-x {
  margin-top: 10px;
}

.mm-hasnavbar-top-1 .mm-panels {
  top: 60px;
}

.mm-panels > .mm-panel.mm-hasnavbar {
  padding-top: 50px;
}

.mm-listview > li:not(.mm-divider)::after {
  left: 0;
}

.mm-listview > li > a, .mm-listview > li > span {
  padding: 0px 10px 0px 20px;
  height: 50px;
  line-height: 48px;
}
