/* ==========================================================================
   #SEARCH RESULTS
   ========================================================================== */

#searchResults h3 {
  word-wrap: break-word;
}

.c-search-controls {
  width: 100%;
  background: #f9f8fb;
  padding: 2rem;
  border: 1px solid #ddd;

  form {
    @include clearfix();
  }
}

.c-search-controls input.c-search-box {
  width: 100%;
  background: #fff;
  display: block;
  margin-bottom: 0.5rem;
  max-width: none;
  border: 1px solid #ccc;

  @include bp-medium {
    width: 48%;
    float: left;
    margin-right: 2%;
  }
}

.c-search-controls input.c-search-box {
  border: 1px solid #ccc;
}

.c-search-controls .c-dropdown {
  width: 100%;
  background: #fff;
  display: block;
  margin-bottom: 0.5rem;
  max-width: none;

  @include bp-medium {
    width: 30%;
    float: left;
    margin-right: 0;
  }
}

.c-search-controls select {
  margin-right: 0;
}

.c-search-controls input.btn {
  clear: both;
  float: left;
  max-width: none;

  @include bp-medium {
    clear: none;
    width: 18%;
    float: left;
    margin-right: 0;
    margin-left: 2%;
  }
}

.c-search-tabs {
  @include clearfix();
  width: 100%;
  margin-bottom: 2rem;

  @include bp-medium {
    height: 50px;
    border-bottom: 1px solid #ddd;
  }
}

  .c-search-tabs ul {
    margin: 0;
    padding: 0;
    list-style-type: none;
    width: 100%;

    @include bp-medium {
      height: 50px;
    }
  }

    .c-search-tabs ul li {
      display: block;
      width: 100%;

      @include bp-medium {
        width: auto;
        float: left;
        margin-right: 1px;
      }
    }

      .c-search-tabs ul li a {
        position: relative;
        text-decoration: none;
        padding: 0 1.5rem;
        height: 50px;
        line-height: 50px;
        display: block;
        color: #444;

        &:hover {
          background: #F1F4F9;
        }
      }

      .c-search-tabs ul li.active a {
        background: #F1F4F9;

        &:after {
          content: '';
          background: var(--color--primary);
          height: 5px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }
      }
  // Search Result Items
  .c-search-result {
    border-bottom: 1px solid #ddd;
    padding-bottom: 1rem;
    margin-bottom: 2rem;
  }

    .c-search-result h3 {
      margin-bottom: 0;
    }

      .c-search-result h3 a {
        color: #444;
      }

    .c-search-result p {
      margin-bottom: 0;
    }

      .c-search-result p a {
        display: block;
        margin-top: 1rem;
        margin-bottom: 1rem;
      }
