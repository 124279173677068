/* ==========================================================================
   #FEATURE POD
   ========================================================================== */

.c-feature-pod {
  background: $color-very-light-grey;
  border-radius:  $global-radius;
  position: relative;
  overflow: hidden;
  margin-bottom: 1rem;

  @include bp-medium {
    min-height: 20rem;
  }

  .c-feature-pod--image {
    display: block;
    width: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    height: 10rem;

    @include bp-medium {
      position: absolute;
      width: 50%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
    }
  }

  .c-feature-pod--content {
    padding: 2rem;
    position: relative;

    @include bp-medium {
      float: right;
      width: 50%;
      height: 100%;
    }

     h3 {
      font-size: 1.3rem;
      line-height: 1.5rem;
      margin-bottom: 1rem;
      font-weight: normal;
      color: $color-secondary-dark;
    }

     .c-btn {
       @include bp-medium {
         position: absolute;
         bottom: 2rem;
         left: 2rem;
       }
     }
  }
}

.u-background-blue-grey .c-feature-pod {background: #fff;}
