/* ==========================================================================
Utilities
  > DD
========================================================================== */

// 2 col

.u-dd--2-col {
  @include clearfix;

  dt, dd {
    float: left;
  }

  dt {
    width: 60%;
  }

  dd {
    width: calc(40% - 8px);
    float: left;
    margin: 0;
    padding-left: 8px;
  }
}