/* ==========================================================================
   #Signposts
   ========================================================================== */

// Large

.c-signpost--large {
  @include t-bg--img;
  min-height: 180px;
  position: relative;

  @include bp-medium {
    min-height: 320px;
  }

  a {
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;

    &:hover h3 {
      text-decoration: underline;
    }

    &:focus {
      outline-color: #fff;
    }


    h3 {
      background: #fff;
      display: inline-block;
      position: absolute;
      bottom: 0;
      left: 0;
      margin: 0;
      padding: 0.5rem 1.5rem;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      width: 100%;

      @include bp-medium {
        bottom: 48px;
        text-align: left;
        font-size: 22px;
        width: auto;
      }
    }
  }
}
