/* ==========================================================================
   #Text Widget
   ========================================================================== */

.c-text-widget {
  width: 100%;
  border: 1px solid #ccc;
  padding: 1.5rem;
  border-left: 8px solid $color-primary;
  margin-bottom: var(--gutter);

  &:last-child {
    margin-bottom: 0;
  }

  &.orange {
    border-left: 8px solid $color-secondary;

    .c-btn {
      background: $color-secondary;
      border-color: $color-secondary;

      &:hover {
        background: darken( $color-secondary, 10% );
        border-color: darken( $color-secondary, 10% );
      }
    }
  }

  &.c-text-widget--plain {
    border-left: 1px solid #ccc;
  }

  h4 {
    color: #444;
  }
}
