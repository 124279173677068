/* ==========================================================================
Components
  > WYSIWYG
========================================================================== */

// Blue (e.g. Awards)

.c-col--wysiwyg--blue {

  h3 {
    @include t-heading--h4;
    color: var(--color--primary--apa);
    margin-bottom: 16px;
  }

  p {
    margin-bottom: calc(var(--gutter) * 1.5);
  }

  ul {
    @include t-list--bullet-custom;
  }
}
