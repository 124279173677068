/* ==========================================================================
   #SEARCH
   ========================================================================== */

.c-search-box {
  margin: auto;
  margin-bottom: 2.5rem;
  max-width: 500px;
  position: relative;

  .c-search-box__btn {
    position: absolute;
    top: 0;
    right: 5px;
    background: none;
    border: 0;
    cursor: pointer;
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;

    @include bp-large {
      width: 45px;
      height: 45px;
    }

    @include bp-full {
      width: 54px;
      height: 54px;
      line-height: 54px;
    }


    &:hover {
      color: $color-secondary;
    }

    &::after {
      content: "\e902";
      font-family: 'icomoon' !important;
      cursor: pointer;
    }
  }
}

// In-Page Search

.c-search {
  width: 100%;
  margin-bottom: 2.5rem;
  max-width: 40em;
  @include clearfix;

  input[type="text"] {
    width: 70%;
    float: left;
  }

  input[type="button"] {
    width: 28%;
    float: right;
  }

 
}
