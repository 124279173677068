/* ==========================================================================
   #CONTACT SIDEBAR
   ========================================================================== */

.c-contact--sidebar {
  padding-left: 2rem;
  border-left: 5px solid $color-red;

  .mi::before {
    color:$color-red;
  }

  .contact-pod {
    position: relative;
    padding-left: 2.5rem;
  }

  .contact-pod span {
    position: absolute;
    left: 0;
    top: 0.3rem;
  }

}
