/* ==========================================================================
   #DATEPICKER
   ========================================================================== */

.ui-datepicker {
    background: #fff;
    width: 300px;
    margin: 5px auto 0;
    -webkit-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
    -moz-box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.5);
}

.c-datepicker__wrapper {
   position: relative;

   &::after {
      position: absolute;
      top: 0.7rem;
      right: 0.7rem;
      content: "\e900";
      font-family: 'icomoon';
      pointer-events: none;
      color: $color-primary-dark;
    }
}

.ui-datepicker table {
    width: 100%;
}

.ui-datepicker-header {
    background: #eee;
    color: #ffffff;
}

.ui-datepicker-title {
    text-align: center;
    font-size: 15px;
    padding: 0.5rem;
    color: #333;
}

.ui-datepicker-prev {
    float: left;
    cursor: pointer;
    margin-top: 7px;
    margin-left: 14px;

    span {
      display: none;
    }

    &::after {
      content: "\edf2";
      font-family: "icomoon";
      color: $color-primary;
    }
}

.ui-datepicker-next {
    float: right;
    cursor: pointer;
    margin-top: 7px;
    margin-right: 14px;

    span {
      display: none;
    }

    &::after {
      content: "\e9ba";
      font-family: "icomoon";
      color: $color-primary;
    }
}

.ui-datepicker thead {
    background-color: #f7f7f7;

    /*border-bottom: 1px solid #bbb;*/
}

.ui-datepicker th {
    text-transform: uppercase;
    font-size: 8pt;
    color: #666666;
    /*text-shadow: 1px 0px 0px #fff;*/
    /*filter: dropshadow(color=#fff, offx=1, offy=0);*/
}

.ui-datepicker tbody td {
    padding: 0;
    border: 1px solid #ddd;
}

    .ui-datepicker tbody td:last-child {
        border-right: 0px;
    }

.ui-datepicker tbody tr {
}

    .ui-datepicker tbody tr:last-child {
        border-bottom: 0px;
    }

.ui-datepicker a {
    text-decoration: none;
}

.ui-datepicker td span, .ui-datepicker td a {
    display: block;
    /*font-weight: bold;*/
    text-align: center;
    width: 40px;
    height: 40px;
    line-height: 40px;
    color: #ffffff;
    background: #f5f5f5;
    /*text-shadow: 1px 1px 0px #fff;*/
    /*filter: dropshadow(color=#fff, offx=1, offy=1);*/
}

.ui-datepicker-calendar {
  margin-bottom: 0;
}

.ui-datepicker-calendar .ui-state-default {
      background: #fff;
      color:#444;
      height:40px;
      width:100%;
      line-height: 40px;
}

.ui-datepicker-calendar .ui-state-disabled {
  background: #f5f5f5;
}

.ui-datepicker-calendar .ui-state-hover {
    background: $color-primary-dark;
    color: #FFFFFF;
}

.ui-datepicker-calendar .ui-state-active {
    background: $color-primary-dark;
    color: #fff;
}

.ui-datepicker-unselectable .ui-state-default {
    background: #D6E4BE;
    color: #000;
}
