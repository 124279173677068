/* ==========================================================================
   #HERO BANNER
   ========================================================================== */

.c-hero-banner {
  @include t-bg--img;
  display: flex;
  min-height: 320px;
  margin: 0 auto 0 auto;
  width: 100%;
  padding: var(--gutter);
  position: relative;
  overflow: hidden;

  @include bp-medium {
    min-height: 400px;
    padding: 48px;
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
    background: rgb(30,55,109);
    background: linear-gradient(45deg, #1e376d 0%, rgba(30, 55, 109, 0.34) 100%);
  }

  &.no-content {
    padding: 0;

    img {
      width: 100%;
    }

    &:before {
      display: none;
    }
  }

  h1 {
    color: #fff;
    line-height: 3rem;
  }

  h2 {
    color: #fff;
    font-size: 30px;
    line-height: 38px;
    font-weight: 700;
    margin-bottom: 22px;
  }

  .c-hero-content {
    align-self: center;
    max-width: 416px;
    position: relative;
    z-index: 2;

    .c-hero-btns {

      a {
        margin-right: 1rem;
        padding: var(--padding--btn--hero);
      }
    }

    p {
      color: #fff;
      font-size: 18px;
      line-height: 28px;
    }
  }
}

@media ( min-width: 375px ) and ( max-width: 767px) {
  .c-hero-banner--alt {
    padding: 120px var(--gutter--alt) 53px var(--gutter--alt);
  }
}
// Hero Feature

.c-hero-feature {
  width: calc(100% + var(--gutter) * 2);
  margin: 0 calc(var(--gutter) * -1);

  @include bp-max-medium {
    .c-hero-feature-left {
      margin-bottom: var(--gutter);
    }
  }

  @include bp-large {
    .c-hero-banner {
      min-height: 480px;
    }
  }

  .c-hero-feature-right {
    padding: 0 var(--gutter);

    @media ( min-width: 375px ) and ( max-width: 767px) {
      padding: 0 var(--gutter--alt);
    }
  }

  @include bp-medium {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    margin: 0;

    .c-hero-feature-left {
      flex: 0 0 65.9375%;
      margin-right: var(--gutter);
    }

    .c-hero-feature-right {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex: 0 0 31.875%;
      padding: 0;

      .c-hero-feature-right--pod {
        flex: 1;
      }
    }
  }

  .c-hero-feature-right--pod {
    @include t-bg--img;
    min-height: 15rem;
    position: relative;

    @include bp-medium {
      min-height: 0;
      margin-top: 0;
    }

    a {
      width: 100%;
      padding: 16px 28px 20px 28px;
      position: absolute;
      bottom: 0;
      left: 0;
      right: 0;
      font-size: 18px;
      line-height: 24px;
      font-weight: 500;
      background: rgba(40,50,72,0.82);
      z-index: 2;
      color: #fff;
    }
  }

    .c-hero-content {
      align-self: flex-end;
    }
  }

  .c-hero-feature-right {
    .c-hero-feature-right--pod:first-child {
      margin-bottom: var(--gutter);
    }
  }
