/* ==========================================================================
   #SITEMAP
   ========================================================================== */

ul.CMSSiteMapList {
  list-style: none;
  position: relative;
  margin: 0;

  > li > ul {
    margin-left: 8px;
  }

  li {
    position: relative;
    margin-bottom: 0;

    a {
      display: block;
      padding: 12px 25px;
      width: 100%;
      color: #444;
      text-decoration: none;
      border-bottom: 1px solid #ddd;
    }
  }
}

ul.CMSSiteMapList > li > a {
  font-weight: 500;
  color: $color-primary-dark;
}

ul.CMSSiteMapList li ul li a {
  background: none;
  text-transform: none;
  color: $color-tertiary;
}

ul.CMSSiteMapList li ul li ul li a {
  margin-bottom: 0;
  font-weight: 400;
}

ul.CMSSiteMapList li ul li a {
  font-weight: 400;
}

ul.CMSSiteMapList li a:hover {
  background: $color-very-light-grey;
}
