/* ==========================================================================
   Tools
     > Headings
   ========================================================================== */

@mixin t-heading--h4 {
  font-size: 22px;
  font-weight: 500;
  line-height: 1.4;
}

@mixin t-heading--h5 {
  font-size: 18px;
  line-height: 26px;
  margin-bottom: 12px;
  font-weight: 600;
  color: #444;
}
