/* ==========================================================================
   #IMAGE CARD
   ========================================================================== */

.c-logo-grid {
  width: 100%;
  margin: auto;
  text-align: center;
}

.c-logo-grid__logo {
  width: 48%;
  display: inline-block;

  @include bp-medium {
    width: 33%;
  }
}

.c-logo-grid__logo a {
  text-align: center;
  display: block;
  height: 180px;
  position: relative;
  border-radius: $global-radius;
}

.c-logo-grid__logo a img {
  width: auto;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  right: 0;
  margin: auto;
  max-width: 80%;
  max-height: 100px;
}
