/* ==========================================================================
Utilities
  > Column Count
========================================================================== */

.u-col-count {
  .col {
    float: none;
    break-inside: avoid;
  }
}

@include bp-xsmall {
  .u-col-count--02--xs {
    column-count: 2;
  }
}

@include bp-small-medium {
  .u-col-count--03--sm {
    column-count: 3;
  }
}

@include bp-large {
  .u-col-count--02--l {
    column-count: 2;
  }
}
