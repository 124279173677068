/* ==========================================================================
Components
  > Sticky
========================================================================== */

// Sidebar

.o-sidebar {

  .c-sticky {
    width: 100%;
    background: #F4F5F9;
    margin-top: var(--gutter);
    padding: 32px;

    @include bp-max-large {
      position: static !important;
    }

    @include bp-large {
      max-width: 260px !important;
      margin-top: 0;
    }

    .h5 {
      margin-bottom: 24px;
    }

    ul {
      list-style: none;
      position: relative;
      margin: 0;
      padding: 0 0 0 16px;

      &:before {
        content: '';
        display: block;
        position: absolute;
        left: -1px;
        width: 1px;
        height: 100%;
        background: var(--color--grey--medium);
      }

      li {
        position: relative;
        line-height: 1.25;

        &.active:before {
          content: '';
          background: var(--color--primary);
          width: 5px;
          height: 100%;
          position: absolute;
          left: -17px;
        }

        a {
          display: block;
          color: $color-text;
          margin: 0 0 8px 0;

          &:hover {
            color: var(--color--primary);
          }
        }
      }
    }
  }
}
