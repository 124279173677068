/* ==========================================================================
   Utilities
     > Type
   ========================================================================== */

// 16

.u-type--16 {
  font-size: 16px;
}

// 18

.u-type--18 {
  font-size: 18px;
}

// 64

.u-type--64 {
  font-size: 64px;
}

// Tertiary

.u-type--tertiary {
  font-size: 16px;
  font-weight: 500;
}

// Medium

.u-type--medium {
  font-size: 20px !important;
  line-height: 28px;
  margin-bottom: 16px !important;
}

// Weights

.u-w--300 {
  font-weight: 300;
}

.u-w--400 {
  font-weight: 400;
}

.u-w--500 {
  font-weight: 500;
}

.u-w--700 {
  font-weight: 700;
}

// Text Transforms

.u-tt--initial {
  text-transform: initial;
}
