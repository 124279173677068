/* ==========================================================================
Components
  > Hamburger
========================================================================== */

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

// Settings
// ==================================================
$hamburger-padding-x           : 15px !default;
$hamburger-padding-y           : 15px !default;
$hamburger-layer-width         : 40px !default;
$hamburger-layer-height        : 4px !default;
$hamburger-layer-spacing       : 6px !default;
$hamburger-layer-color         : #000 !default;
$hamburger-layer-border-radius : 4px !default;
$hamburger-hover-opacity       : 0.7 !default;
$hamburger-active-layer-color  : $hamburger-layer-color !default;
$hamburger-active-hover-opacity: $hamburger-hover-opacity !default;

@import "c-hamburger__base";

// Hamburger types
// ==================================================

.c-hamburger {
.c-hamburger-inner {
  &,
  &::before,
  &::after {
    transition-property: none;
  }
}

&.is-active {
  .c-hamburger-inner {
    transform: rotate(45deg);

    &::before {
      top: 0;
      opacity: 0;
    }

    &::after {
      bottom: 0;
      transform: rotate(-90deg);
    }
  }
}
}

