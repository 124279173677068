
/* ==========================================================================
Utilities
  > Animations
========================================================================== */

// Base

.u-animate {
  animation-duration: 0.66s;
  animation-fill-mode: both;
}

// Infinte

.u-animate.infinite {
  animation-iteration-count: infinite;
}

// fade In Up

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    visibility: visible;
    transform: translate3d(0, 0, 0);
  }
}

.u-animate-fadeInUp {
  animation-name: fadeInUp;
}

// Delays

.u-animate--delay--200ms {
  animation-delay: 200ms;
}

.u-animate--delay--400ms {
  animation-delay: 400ms;
}

