/* ==========================================================================
   #FORM
   ========================================================================== */

// Shared

input,
select,
textarea {
  @include t-form--ui;
}

.c-form {
  @include t-form--ui--alt;
}

button {
  font-family: $font-body;
}

// Textarea

textarea {
  height: 140px;
  padding: 4px 16px 16px 16px;
  border-radius: 5px;
}

// Select

.c-dropdown {
  position: relative;

  &:before { // Note: Chevron code being read to SR. Need to move inline.
    position: absolute;
    right: 16px;
    top: 50%;
    transform:translateY(-50%);
    font-size: 12px;
    font-family: 'icomoon' !important;
    content: "\e915";
    font-style: normal;
    color: #444;
    pointer-events: none;
  }

  select {
    appearance: none;
    cursor: pointer;
    padding-left: 12px;
    padding-right: 40px;
  }
}

select::-ms-expand {
  display: none;
}

// Checkboxes & Radios

.checkbox,
.c-checkbox,
.c-radio {
  position: relative;
  margin: 0;
  display: block;
}

.checkbox input,
.c-checkbox input,
.c-radio input {
  display: block;
  width: 24px;
  height: 24px;
  margin: 0 0 -24px -9999px;
  padding: 0;
  outline: none;
}

.checkbox label,
.c-checkbox label,
.c-radio label {
  margin: 0;
  position: relative;
  cursor: pointer;
  font-size: 16px;
  color: $color-text;
  display: block;
}

// Focus

.c-checkbox input:focus + label:before,
.c-radio input:focus + label:before{
  @include shadow(1);
}

/* Kentico Forms Checkboxes */

.ktc-checkbox input {
  width: auto;
  display: inline-block;
  height: auto;
}

.ktc-checkbox label {
  width: auto;
  display: inline-block;
  height: auto;
  font-size: 16px;
  margin-left: 0.5rem;
}

form[id*="form-"] input[type="submit"] {
  margin-left: 1rem;
  margin-top: 1rem;
  float: left;
  clear: both;
}

.required-field-red-star::after {
  content: "*";
  color: red;
}

.ktc-radio, .ktc-checkbox {
  margin-right: 1rem;
}

.ktc-radio input, .ktc-checkbox input {
  height: auto;
  line-height: 1rem;
  background: none;
  border: 0;
  border-radius: 0;
  font-weight: 300;
  width: auto;
  color: #444;
  display: inline-block;
}

.ktc-radio label, .ktc-checkbox label {
  display: inline-block;
  font-size: 1rem;
}

/* Labels */

label {
  display: block;
}

.c-radio label, .c-checkbox label, .checkbox label {
  padding-left: 30px;
}

// Radio

.c-radio label::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  display: block;
  border: 1px solid $color-medium-grey;
  width: 19px;
  height: 19px;
  font-size: 12px;
  border-radius: 100%;
}

.c-radio input:checked + label::after {
  position: absolute;
  content: "";
  top: 5px;
  left: 5px;
  display: block;
  background: $color-primary;
  width: 9px;
  height: 9px;
  font-size: 12px;
  border-radius: 100%;
}

// Checkbox

.checkbox label::before,
.c-checkbox label::before {
  position: absolute;
  content: "";
  top: 3px;
  left: 0;
  display: block;
  border: 1px solid $color-medium-grey;
  width: 16px;
  height: 16px;
}

.checkbox input:checked + label::after,
.c-checkbox input:checked + label::after {
  font-family: 'icomoon' !important;
  content: "\e922";
  position: absolute;
  top: 4px;
  left: 2px;
  display: block;
  color: $color-primary;
  font-size: 10px;

  @include bp-large {
    //top: -2px;
  }

  @include bp-xlarge {
    //top: 0px;
  }
}

// Radio Boxes

.c-radio__box {
  display: inline-block;
  margin: 0 $gutter / 2;
  text-align: center;

  &.is-active .c-radio__content {
    border: 1px solid $color-primary;
  }

  &.is-active .c-radio__content label {
    color: $color-primary;
  }

  .c-radio__content {
    position: relative;
    color: $color-primary;
    border: 1px solid #DBDBDB;
    padding: 6px 16px 8px 16px;
    background-image: linear-gradient(0deg, rgba(0,0,0,0.05) 0%, rgba(0,0,0,0.00) 55%);
    border-radius: 8px;
    margin-bottom: 8px;
    cursor: pointer;


    label {
      margin: 0;
      color: $color-primary;
      font-size: 20px;

      @include bp-medium {
        font-size: 38px;
      }
    }

    &:hover {
      border: 1px solid $color-primary;
    }

    @include bp-medium {
      padding: 16px 32px 22px 32px;
    }

    input[type="radio"] {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      cursor: pointer;
      opacity: 0;
      padding: 0;
      border-radius: 0;
      z-index: 5;
    }
  }
}

.c-radio__box--text {

  .c-radio__content {

    input {
      border: 0;
      border-radius: 0;
      background: 0;
      font-weight: 600;
      width: 120px;
      padding: 0;
      text-align: center;
      color: $color-primary;
    }

    @include bp-medium {
      padding: 19px 32px 22px 32px;
      font-size: 2rem;
    }
  }
}

// Responsive Radio Groups

.c-radio__group--responsive {

  .c-radio__box {
    margin: 0;
    float: left;
    padding-left: $gutter / 2;
    padding-right: $gutter / 2;
    width: 50%;

    @include bp-small {
      width: 25%;
    }
  }
}

// Fieldset

fieldset {
  margin-bottom: 0.5rem;

  h4 {
    margin-bottom: 1.5rem;
    font-weight: normal;
    color: $color-secondary-dark;
  }

  legend {
    color: $color-primary;
    font-size: 1.125rem;
    line-height: 1.5;
    margin-bottom: 1.5rem;
  }

  input, select, textarea {
    margin-bottom: 0.750rem;
  }

  .row {
    margin-bottom: 0;
  }
}

/* Placeholders ) */

::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: $color-text;
  opacity: 1;
}

::-moz-placeholder { /* Firefox 19+ */
  color: $color-text;
  opacity: 1;
}

:-ms-input-placeholder { /* IE 10+ */
  color: $color-text;
  opacity: 1;
}

:-moz-placeholder { /* Firefox 18- */
  color: $color-text;
  opacity: 1;
}

.WatermarkText {
  color: $color-medium-grey;
}

/* Focus */

input:focus::-webkit-input-placeholder {
  opacity: 1;
}

input:focus::-moz-placeholder {
  opacity: 1;
}

input:focus:-ms-input-placeholder {
  opacity: 1;
}

input:focus:-moz-placeholder {
  opacity: 1;
}

textarea:focus::-webkit-input-placeholder {
  opacity: 1;
}

textarea:focus::-moz-placeholder {
  opacity: 1;
}

textarea:focus:-ms-input-placeholder {
  opacity: 1;
}

textarea:focus:-moz-placeholder {
  opacity: 1;
}

/* Textarea */

textarea.form-control::-moz-placeholder, .form-group textarea.form-control::-moz-placeholder {
  line-height: inherit !important;
}

textarea.form-control:-ms-input-placeholder, .form-group textarea.form-control:-ms-input-placeholder {
  line-height: inherit !important;
}

textarea.form-control::-webkit-input-placeholder, .form-group textarea.form-control::-webkit-input-placeholder {
  line-height: inherit !important;
}

/* Placeholders - Search */

.c-search .form-control::-moz-placeholder, .c-search .form-group .form-control::-moz-placeholder {
  font-size: 18px;
}

.c-search .form-control:-ms-input-placeholder, .c-search .form-group .form-control:-ms-input-placeholder {
  font-size: 18px;
}

.c-search .form-control::-webkit-input-placeholder, .c-search .form-group .form-control::-webkit-input-placeholder {
  font-size: 18px;
}

/* Captchas */

.CaptchaTextBox {
  text-align: center;
}

label[for*="GoogleRecaptchaV3Component"] {
  display: none;
}

// Asterisk

.c-asterisk {
  padding-left: 20px;
  position: relative;
  font-size: 0.875rem;

  &::before {
    content: "*";
    position: absolute;
    top: 0;
    left: 0;
    color: $color-primary;
    font-size: 1.2rem;
  }
}

// Validation
.field-validation-error {
  color: red;
}

// Floating Labels

.c-label--float {
  @include float-label-container;
  @include float-label;
  display: block;
  width: 100%;

  select {
    appearance: none;
  }

  input, select, textarea {
    @include float-label-input;

    @include float-label-scaled {
      font-size: initial;
      color: #444;
      top: 8px;
      left: 16px;
    }
  }

  &.c-label--float--l {
    input {
      @include float-label-scaled {
        left: 24px;
        font-size: 18px;
      }

      &:focus {
        @include shadow(2);
      }
    }
  }
}

/*.c-label--float {
  position: relative;
  width: 100%;

  label {
    position: absolute;
    top: 50%;
    left: 16px;
    pointer-events: none;
    transform: translateY(-50%);
    transition: $global-transition;
  }

  &.c-label--float--l {
    font-size: 18px;

    label {
      left: 24px;
    }
  }

  &.is--floating label {
    top: -10px;
    font-size: 13px;
    font-weight: 600;
  }

  &.has--focus label {
    color: var(--color--primary--navy);
  }
}*/