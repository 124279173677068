/* ==========================================================================
   Tools
     > Resets
   ========================================================================== */

// Buttons

@mixin t-reset--btn {
  padding: 0;
  border: none;
  font: inherit;
  color: inherit;
  background-color: transparent;

  &:enabled {
    cursor: pointer;
  }
}
